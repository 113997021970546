import React from 'react';

export default function WorkSideBlock() {
  return (
    <svg
      id="block_left"
      x="0px"
      y="0px"
      width="100%"
      height="100%"
      viewBox="0 0 400 400"
      preserveAspectRatio="none"
    >
      <g id="Scene_1_l1" overflow="visible">
        <g style={{ display: 'none' }}>
          <path
            fill="#000"
            fillOpacity="0.098"
            d="M400,400L400,0 0,0 0,400 400,400"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.792s"
            keyTimes="0;1;1"
            values="none;inline;inline"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill="#000"
            fillOpacity="0.098"
            d="M384,400Q409.4,199.25 384,0L-7.25,0 -7.25,400 384,400"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.792s"
            keyTimes="0;0.947;1"
            values="none;inline;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill="#000"
            fillOpacity="0.098"
            d="M338.4,400Q436.1,197.15 338.4,0L-28,0 -28,400 338.4,400"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.792s"
            keyTimes="0;0.895;0.947;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill="#000"
            fillOpacity="0.098"
            d="M263.2,400Q480.15,193.65 263.2,0L-62.15,0 -62.15,400 263.2,400"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.792s"
            keyTimes="0;0.842;0.895;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill="#000"
            fillOpacity="0.098"
            d="M158.45,400Q541.55,188.8 158.45,0L-109.75,0 -109.75,400 158.45,400"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.792s"
            keyTimes="0;0.789;0.842;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill="#000"
            fillOpacity="0.098"
            d="M81.55,400Q528.25,188.15 81.55,0L-154.65,0 -154.65,400 81.55,400"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.792s"
            keyTimes="0;0.737;0.789;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill="#000"
            fillOpacity="0.098"
            d="M9.95,400Q515.9,187.55 9.95,0L-196.45,0 -196.45,400 9.95,400"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.792s"
            keyTimes="0;0.684;0.737;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill="#000"
            fillOpacity="0.098"
            d="M-56.35,0L-235.15,0 -235.15,400 -56.35,400Q504.45,187 -56.35,0"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.792s"
            keyTimes="0;0.632;0.684;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill="#000"
            fillOpacity="0.098"
            d="M-117.3,0L-270.8,0 -270.8,400 -117.3,400Q493.9,186.5 -117.3,0"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.792s"
            keyTimes="0;0.579;0.632;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill="#000"
            fillOpacity="0.098"
            d="M-173,0L-303.3,0 -303.3,400 -173,400Q484.25,186.05 -173,0"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.792s"
            keyTimes="0;0.526;0.579;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill="#000"
            fillOpacity="0.098"
            d="M-223.35,0L-332.75,0 -332.75,400 -223.35,400Q475.55,185.65 -223.35,0"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.792s"
            keyTimes="0;0.474;0.526;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill="#000"
            fillOpacity="0.098"
            d="M-268.45,0L-359.05,0 -359.05,400 -268.45,400Q467.8,185.25 -268.45,0"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.792s"
            keyTimes="0;0.421;0.474;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill="#000"
            fillOpacity="0.098"
            d="M-308.25,0L-382.3,0 -382.3,400 -308.25,400Q460.9,184.95 -308.25,0"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.792s"
            keyTimes="0;0.368;0.421;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill="#000"
            fillOpacity="0.098"
            d="M-342.7,0L-402.4,0 -402.4,400 -342.7,400Q454.95,184.65 -342.7,0"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.792s"
            keyTimes="0;0.316;0.368;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill="#000"
            fillOpacity="0.098"
            d="M-371.85,0L-419.45,0 -419.45,400 -371.85,400Q449.9,184.4 -371.85,0"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.792s"
            keyTimes="0;0.263;0.316;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill="#000"
            fillOpacity="0.098"
            d="M-395.75,0L-433.4,0 -433.4,400 -395.75,400Q445.8,184.2 -395.75,0"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.792s"
            keyTimes="0;0.211;0.263;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill="#000"
            fillOpacity="0.098"
            d="M-414.3,0L-444.2,0 -444.2,400 -414.3,400Q442.55,184.05 -414.3,0"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.792s"
            keyTimes="0;0.158;0.211;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill="#000"
            fillOpacity="0.098"
            d="M-427.55,0L-451.95,0 -451.95,400 -427.55,400Q440.3,183.95 -427.55,0"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.792s"
            keyTimes="0;0.105;0.158;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill="#000"
            fillOpacity="0.098"
            d="M-435.5,0L-456.6,0 -456.6,400 -435.5,400Q438.9,183.85 -435.5,0"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.792s"
            keyTimes="0;0.053;0.105;1"
            values="none;inline;none;none"
          />
        </g>
        <g>
          <path
            fill="#000"
            fillOpacity="0.098"
            d="M-438.15,0L-458.15,0 -458.15,400 -438.15,400Q438.45,183.85 -438.15,0"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.792s"
            keyTimes="0;0.053;1"
            values="inline;none;none"
          />
        </g>
      </g>
    </svg>
  );
}

import { Power1, TimelineMax } from 'gsap/all';
// eslint-disable-next-line
import morphSVG from '../plugins/MorphSVGPlugin';

let tlArray = [];

export default class MorphingBG {
  constructor(el) {
    this.DOM = {};
    this.DOM.el = el;
    this.DOM.paths = Array.from(
      this.DOM.el.querySelectorAll('.background-path')
    );
    this.animate();
  }

  animate() {
    this.DOM.paths.forEach(path => {
      const startId = `#${path.attributes.id.value}`;
      const targetId = `${startId}_end`;

      const tl = new TimelineMax({
        repeat: -1,
        yoyo: true
      });

      tl.to(startId, 7, {
        morphSVG: { shape: targetId, shapeIndex: [0] },
        ease: Power1.easeInOut
      });

      tlArray.push(tl);
    });
  }

  pause() {
    tlArray.forEach(tl => {
      tl.pause();
    });
  }

  resume() {
    tlArray.forEach(tl => {
      tl.resume();
    });
  }
}

import React, { Component } from 'react';
import MorphingBG from './morphingBG';

class Background extends Component {
  componentDidMount() {
    new MorphingBG(document.querySelector('svg.background'));
  }

  render() {
    return (
      <svg
        x="0px"
        y="0px"
        className="background"
        width="100%"
        height="100%"
        viewBox="0 0 1366.672 762.317"
        enableBackground="new 0 0 1366.672 762.317"
        preserveAspectRatio="none"
      >
        <defs>
          <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="100%" stopColor="#ac8dae" stopOpacity="0.8" />
            <stop offset="0%" stopColor="#ac8dae" stopOpacity="0" />
          </linearGradient>
        </defs>

        <path
          className="background-path"
          id="light_pink_slice"
          fill="#F0DFEC"
          d="M1038.563,651.077c6.131,38.354,18.21,68.741,38.998,111.24h111.872
		c0.267-46.129-2.932-120.245-2.932-120.245s-18.123-115.995-54.504-183.478c-36.38-67.481,4.131-193.325,0-290.349
		c-3.332-80.544-89.818-148.648-122.468-170.935H862.81c27.052,26.226,108.741,111.434,146.188,220.38
		C1054.439,349.234,1029.502,594.271,1038.563,651.077"
        />
        <path
          className="background-path"
          id="pink_slice"
          fill="#F8CCDF"
          d="M909.57,762.317h200.99c-42.064-60.919-47.169-105.732-52.498-144.087
		c-7.996-56.806,23.483-263.932-16.229-395.475C1008.918,113.809,913.449,23.536,889.729-2.689H635.74
		C827.074,207.1,927.197,502.132,917.069,762.317"
        />
        <path
          className="background-path"
          id="pink_corner"
          fill="#F5BCD4"
          d="M1079.062,331.593c50.239,111.849,26.689,127.823,77.996,242.988
		c31.183,69.868,53.997,140.993,32.376,187.737h177.238V-2.689H846.818c35.314,46.958,12.101,110.365,38.753,142.292
		C944.073,209.47,1032.819,228.763,1079.062,331.593"
        />
        <path
          className="background-path"
          id="pink_slice_2"
          fill="#F8CCDF"
          d="M1366.672,762.317c-16.657-42.5-76.121-163.737-72.646-263.793c1.988-57.242-4.113-149.29-40.627-280.833
		c-30.251-108.947-115.696-194.155-137.553-220.38H983.386c158.313,148.025,63.458,501.297,193.788,765.007H1366.672z"
        />
        <path
          className="background-path"
          id="blue_sliver"
          fill="#D8ECF7"
          d="M676.509,455.339c27.65,88.105,11.596,59.574,62.9,174.739c31.184,69.868,106.21,87.874,107.409,132.24
		h70.251c10.128-260.187-73.819-514.67-233.733-765.008H294.601c77.939,38.292,156.031,111.865,182.683,143.792
		c58.501,69.867,99.765,82.271,146.006,185.102L676.509,455.339"
        />
        <path
          className="background-path"
          id="blue_back"
          fill="#B5DCF5"
          d="M846.818,762.317c-1.199-44.366-87.555-90.871-118.739-160.738
		c-51.439-115.166-36.755-126.639-86.995-238.488c-46.108-102.726-91.49-146.123-149.993-215.989
		C463.506,114.138,369.598,13.609,296.101-2.689H2.072v765.007H846.818z"
        />
        <path
          className="background-path"
          id="corner_pink_3"
          fill="#F0DFEC"
          d="M753.578,762.317c-70.242-30.244-259.3-113.607-286.485-168.237
		c-37.713-75.671-57.348-198.494-160.492-353.982C209.187,96.84,137.385,79.72,20.115-2.689H0.741v765.007H753.578z"
        />
        <path
          className="background-path"
          id="corner_pink_2"
          fill="#F8CCDF"
          d="M477.284,762.317c-29.451-78.781-120.336-206.916-224.68-261.233C143.862,444.485,60.901,342.041,0,292.595
		v469.723H477.284z"
        />
        <path
          className="background-path"
          id="corner_pink"
          fill="#F5BCD4"
          d="M398.096,762.317c-19.457-50.896-56.051-185.236-167.992-193.736
		C120.297,560.288,35.122,497.805,0.74,483.085v279.232H398.096z"
        />
        <path
          visibility="hidden"
          id="light_pink_slice_end"
          fill="#F0DFEC"
          d="M1071.764,618.23c6.131,38.354,27.719,101.588,48.507,144.087h69.163
		c0.267-46.129-2.932-120.245-2.932-120.245s-18.123-115.995-54.504-183.478c-36.38-67.481,4.131-193.325,0-290.349
		c-3.332-80.544-89.818-148.648-122.468-170.935H862.81c27.052,26.226,108.741,111.434,146.188,220.38
    C1054.439,349.234,1062.702,561.425,1071.764,618.23"
        />
        <path
          id="pink_slice_end"
          visibility="hidden"
          fill="#F8CCDF"
          d="M976.082,762.317h168.576c-18.123-42.5-37.181-105.732-42.51-144.087
		c-7.996-56.806-44.511-268.996-84.223-400.54C985.01,108.744,913.449,23.536,889.729-2.689h-150.32
		C899.324,247.648,986.21,502.132,976.082,762.317"
        />
        <path
          id="pink_corner_end"
          visibility="hidden"
          fill="#F5BCD4"
          d="M1121.471,317.204c50.239,111.849,29.717,145.538,81.023,260.703
		c31.183,69.868,38.912,140.046,40.11,184.412h124.067V-2.689H815.768c35.314,46.958,75.16,98.788,101.812,130.715
		C976.082,197.892,1075.229,214.373,1121.471,317.204"
        />
        <path
          id="pink_slice_2_end"
          visibility="hidden"
          fill="#F8CCDF"
          d="M1342.685,762.317c-16.657-42.5-33.981-105.732-38.912-144.087
		c-7.329-56.806-13.859-268.996-50.373-400.54c-30.251-108.947-95.681-194.155-117.537-220.38h-132.461
		c158.313,148.025,43.442,501.297,173.772,765.007H1342.685z"
        />
        <path
          id="blue_sliver_end"
          visibility="hidden"
          fill="#D8ECF7"
          d="M725.683,317.204c50.239,111.849,29.719,145.538,81.023,260.703
		c31.184,69.868,38.913,140.046,40.112,184.412h129.264c10.128-260.187-76.759-514.67-236.673-765.008H419.981
		c35.313,46.958,75.16,98.788,101.812,130.715C580.295,197.892,679.441,214.373,725.683,317.204"
        />
        <path
          id="blue_back_end"
          visibility="hidden"
          fill="#B5DCF5"
          d="M846.818,762.317c-1.199-44.366-8.928-114.543-40.112-184.41
		c-51.439-115.166-30.783-148.854-81.023-260.704c-46.108-102.726-145.388-119.312-203.89-189.178
		C494.208,95.062,456.495,45.098,420.647-2.689H2.072v765.007H846.818z"
        />
        <path
          id="corner_pink_3_end"
          visibility="hidden"
          fill="#F0DFEC"
          d="M610.812,762.317c-12.261-34.933-35.581-88.628-62.767-143.258
		c-37.713-75.671-63.833-196.538-166.977-352.026C283.655,123.775,164.252,79.72,46.982-2.689H0.741v765.007H610.812z"
        />
        <path
          id="corner_pink_2_end"
          visibility="hidden"
          fill="#F8CCDF"
          d="M477.284,762.317c-29.451-78.781-96.348-225.355-200.692-279.673
		C167.85,426.046,64.439,393.497,3.538,344.051v418.266H477.284z"
        />
        <path
          id="corner_pink_end"
          visibility="hidden"
          fill="#F5BCD4"
          d="M352.551,762.317c-19.457-50.896-62.632-133.928-174.573-142.428
		C68.17,611.597,35.122,596.877,0.74,582.157v180.16H352.551z"
        />
        <g id="overlay" opacity="0.25">
          <g>
            <g>
              <defs>
                <rect
                  id="SVGID_1_"
                  x="-560.296"
                  y="-215.702"
                  width="2146.395"
                  height="1202.113"
                />
              </defs>
              <clipPath id="SVGID_2_">
                <use xlinkHref="#SVGID_1_" overflow="visible" />
              </clipPath>
              <path
                clipPath="url(#SVGID_2_)"
                fill="#F8CCDF"
                d="M53.468-215.705h-264.897c28.479,66.784,58.424,166.146,66.801,226.416
				c12.564,89.263,69.941,422.695,132.344,629.4C39.439,811.307,151.889,945.2,189.163,986.411h236.208
				C174.086,593.036,37.554,193.145,53.468-215.705"
              />
              <path
                clipPath="url(#SVGID_2_)"
                fill="#F0DFEC"
                d="M-96.884,10.71c-9.633-60.269-43.557-159.631-76.224-226.415h-108.681
				c-0.419,72.485,4.607,188.951,4.607,188.951s28.479,182.272,85.647,288.312c57.166,106.041-6.492,303.786,0,456.251
				C-186.299,844.373-50.396,951.39,0.908,986.411h230.555C188.954,945.2,60.588,811.307,1.746,640.11
				C-69.662,433.405-82.645,99.973-96.884,10.71"
              />
              <path
                clipPath="url(#SVGID_2_)"
                fill="#F5BCD4"
                d="M-174.992,483.738c-78.946-175.757-46.698-228.696-127.318-409.665
				c-49.001-109.787-61.147-220.062-63.031-289.778h-194.956V986.411h865.679c-55.492-73.788-118.104-155.232-159.986-205.401
				C53.468,671.223-102.328,645.322-174.992,483.738"
              />
              <path
                clipPath="url(#SVGID_2_)"
                fill="#D8ECF7"
                d="M446.94,483.738c-78.946-175.757-46.698-228.696-127.318-409.665
				c-49.001-109.787-61.146-220.062-63.031-289.778H53.469C37.554,193.145,174.085,593.036,425.371,986.411h501.943
				C871.822,912.623,809.21,831.179,767.329,781.01C675.4,671.223,519.604,645.322,446.94,483.738"
              />
              <path
                clipPath="url(#SVGID_2_)"
                fill="#B5DCF5"
                d="M256.591-215.705c1.885,69.716,14.03,179.992,63.031,289.779
				c80.83,180.968,48.372,233.907,127.318,409.665C519.395,645.16,675.4,671.223,767.329,781.01
				c43.348,51.798,102.607,130.311,158.938,205.401h657.74V-215.705H256.591z"
              />
              <path
                clipPath="url(#SVGID_2_)"
                fill="#F0DFEC"
                d="M627.447-215.705c19.265,54.894,55.91,139.27,98.629,225.112
				c59.262,118.909,100.305,308.836,262.385,553.168c153.074,225.112,340.7,294.34,524.977,423.836h72.664V-215.705H627.447z"
              />
              <path
                clipPath="url(#SVGID_2_)"
                fill="#F8CCDF"
                d="M837.27-215.705c46.278,123.795,151.399,354.12,315.363,439.473
				c170.876,88.938,333.373,140.085,429.071,217.782v-657.255H837.27z"
              />
            </g>
          </g>
        </g>
        <rect
          opacity="0.3"
          className="color-difference"
          x="0"
          y="0"
          fill="#2e3192"
          width="100%"
          height="100%"
        />

        <rect
          className="color-saturation"
          x="0"
          y="0"
          fill="#ffff00"
          opacity="0.5"
          width="100%"
          height="100%"
        />
      </svg>
    );
  }
}

export default Background;

import React from 'react';
import classnames from 'classnames';
import ImagePod from '../components/imagePod';

function AlignmentQuote(props) {
  const classes = classnames('alignment-quote', props.className);

  return (
    <div className={classes}>
      <div className="alignment-quote__content">
        <div className="alignment-quote__text">{props.quote}</div>
        <div className="alignment-quote__attribution">
          - {props.attribution}
        </div>
      </div>
      <ImagePod
        direction="right"
        hex="#248dc1"
        key={props.image.ID}
        img={props.image}
        index={props.index}
      />
    </div>
  );
}

export default AlignmentQuote;

import React, { Component } from 'react';
import { IoIosArrowDropleft } from 'react-icons/io';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const propTypes = {
  answer: PropTypes.shape({
    response: PropTypes.string.isRequired
  }),
  backCallback: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
  className: PropTypes.string,
  data: PropTypes.shape({
    contact_form_slide_options: PropTypes.arrayOf(
      PropTypes.shape({
        contact_form_slide_option: PropTypes.string
      })
    ),
    contact_form_slide_title: PropTypes.string.isRequired
  }),
  slideNumber: PropTypes.number.isRequired
};

class ContactSlide extends Component {
  render() {
    const classes = classnames('contact-slide', this.props.className);
    return (
      <div className={classes}>
        <div className="contact-page__title">
          {this.props.data.contact_form_slide_title}
        </div>
        <div className="contact-page__options">
          {this.props.data.contact_form_slide_options.map((option, index) => {
            const classes = classnames('contact-page__option', {
              selected:
                this.props.answer &&
                this.props.answer.response === option.contact_form_slide_option
            });
            return (
              <button
                key={index}
                type="button"
                onClick={() =>
                  this.props.callback(
                    option.contact_form_slide_option,
                    this.props.slideNumber
                  )
                }
                className={classes}
              >
                {option.contact_form_slide_option}
              </button>
            );
          })}
        </div>
        {this.props.slideNumber > 0 && (
          <div className="contact-actions">
            <div
              onClick={() => this.props.backCallback(this.props.slideNumber)}
              className="contact-nav"
            >
              <IoIosArrowDropleft /> Back
            </div>
          </div>
        )}
      </div>
    );
  }
}

ContactSlide.propTypes = propTypes;
export default ContactSlide;

import React, { Component } from 'react';
import { ReactGA } from '../config';

export default class NoMatch extends Component {
  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
  }
  render() {
    return <div className="page-no-match">404</div>;
  }
}

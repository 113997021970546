import React from 'react';
import ImagePod from '../components/imagePod';
import classnames from 'classnames';

export default function ProcessDetailItem(props) {
  const classes = classnames('process-detail__item', props.className);
  return (
    <div className={classes}>
      <div className="process-detail__text">
        <div className="process-detail__title">{props.title}</div>
        <div className="process-detail__description">{props.description}</div>
      </div>
      <ImagePod direction="right" hex="#60288c" img={props.image} />
    </div>
  );
}

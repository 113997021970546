import React from 'react';
import PropTypes from 'prop-types';

import ImagePod from '../components/imagePod';

const propTypes = {
  data: PropTypes.shape({
    client_name: PropTypes.string,
    project_name: PropTypes.string,
    thumbnail: PropTypes.string
  }),
  index: PropTypes.number
}
function WorkItem(props) {
  const { project_name, thumbnail, client_name } = props.data;
  return (
    <div className="work-item" onClick={e => props.callback(e, props.name)}>
      <div className="work-item__content">
        <div className="work-item__client">{client_name}</div>
        <div className="work-item__project">{project_name}</div>
      </div>
      <div className="work-item__background">
        <ImagePod
          key={props.index}
          direction="top"
          hex="#ff33ad"
          img={thumbnail}
          index={props.index}
        />
      </div>
    </div>
  );
}

WorkItem.propTypes = propTypes;
export default WorkItem;

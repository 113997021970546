import React, { Component, Fragment } from 'react';
import classnames from 'classnames';
import { Helmet } from 'react-helmet';
import API from '../services/api';
import { TweenMax, Power2 } from 'gsap/all';
import { config, ReactGA } from '../config';
import $ from 'jquery';
import moment from 'moment';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon
} from 'react-share';
import socialImage from '../images/social/writing.jpg';

import WordsPageInfo from '../components/wordsPageInfo';
import Loader from '../components/loader';
import SideBlock from '../components/sideBlock';
import WordItem from '../components/wordItem';
import BackButton from '../components/backButton';
import Scroll from '../helpers/scroll';
import ImagePod from '../components/imagePod';
import history from '../helpers/history';

export default class Words extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      data: {
        acf: {
          word: []
        }
      },
      loaded: false,
      sideBlock: false,
      slideNumber: -1,
      wordIndex: 0,
      words: []
    };

    this.delta = 0;
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
    API.retrieveContent({ prefix: 'page', path: 'theory' }).then(res => {
      this.setState({ data: res.data });

      // load actual work
      API.retrieveContent({ prefix: '', path: 'words' }).then(res => {
        this.setState({ words: res.data });
        setTimeout(() => {
          this.setState({ loaded: true });
        }, 10);
        if (this.props.match.params.slug) {
          let selectedIndex = null;
          this.state.words.forEach((word, index) => {
            if (word.post_name === this.props.match.params.slug) {
              selectedIndex = index + 1;
            }
          });

          if (selectedIndex) {
            this.scrollCallback(1);
            this.changeWord(selectedIndex);
          } else {
            history.push('/writing/');
          }
        }
      });
    });
  }

  changeWord(index) {
    const interval = this.state.wordIndex > -1 ? 0 : 1000;

    history.push(`/writing/${this.state.words[index - 1].post_name}`);
    ReactGA.pageview(window.location.pathname);

    TweenMax.set('.words-detail__meta', {
      opacity: 0
    });

    TweenMax.set('.words-detail__description', {
      opacity: 0
    });

    this.setState({ transitioning: true });

    setTimeout(() => {
      this.setState({ wordIndex: index, transitioning: false }, () => {
        $('.main-content').animate(
          {
            scrollTop:
              $('#wordsScroll').offset().top -
              $('.content-container').offset().top
          },
          1000
        );
        setTimeout(() => {
          this.setState({ collapsed: true });
        }, 1000);

        setTimeout(() => {
          TweenMax.fromTo(
            '.words-detail__meta',
            0.5,
            {
              opacity: 0,
              y: 30
            },
            {
              opacity: 1,
              y: 0
            }
          ).delay(0.2);
        }, interval);

        setTimeout(() => {
          TweenMax.fromTo(
            '.words-detail__description',
            0.5,
            {
              opacity: 0,
              y: 30
            },
            {
              opacity: 1,
              y: 0
            }
          ).delay(0.4);
        }, interval);
      });
    }, interval);
  }

  back = () => {
    history.push('/writing/');
    ReactGA.pageview(window.location.pathname);

    this.setState({ collapsed: false });
    setTimeout(() => {
      this.setState({ wordIndex: -1 });
    }, 500);
  };

  scrollCallback = index => {
    this.delta = index;

    this.setState({ slideNumber: index });
    if (index === 0) {
      TweenMax.staggerFromTo(
        '.word-list-item',
        0.3,
        {
          opacity: 1,
          y: 0,
          ease: Power2.easeOut
        },
        {
          opacity: 0,
          y: 30
        },

        0.1
      );
      setTimeout(() => {
        this.setState({ sideBlock: false });
      }, 500);
    }
    if (index === 1) {
      setTimeout(() => {
        this.setState({ sideBlock: true });
      }, 300);

      setTimeout(() => {
        TweenMax.staggerFromTo(
          '.word-list-item',
          0.3,
          {
            opacity: 0,
            y: 30
          },
          {
            opacity: 1,
            y: 0,
            ease: Power2.easeOut
          },
          0.1
        );
      }, 1200);
    }
  };

  render() {
    let wordsDetailContent;
    let title;
    let hashtags = [];

    const classes = classnames(
      { loaded: this.state.loaded },
      'page-words',
      'page'
    );

    const wordsClasses = classnames('words-content', {
      condensed: this.state.slideNumber === 1,
      collapsed: this.state.wordIndex > 0,
      transitioning: this.state.transitioning
    });

    const imageClasses = classnames('words-container', {
      expanded: this.state.slideNumber === 1
    });

    const wordsDetailClasses = classnames('words-detail', {
      expanded: this.state.wordIndex > 0
    });

    if (this.state.wordIndex > 0) {
      wordsDetailContent = {
        image: this.state.words[this.state.wordIndex - 1].acf.image.sizes.large,
        post_author: this.state.words[this.state.wordIndex - 1].author_name,
        post_content: this.state.words[this.state.wordIndex - 1].post_content,
        post_date: this.state.words[this.state.wordIndex - 1].post_date,
        post_title: this.state.words[this.state.wordIndex - 1].post_title
      };

      title =
        this.state.words[this.state.wordIndex - 1].acf.social_title ||
        wordsDetailContent.post_title;
      hashtags = this.state.words[this.state.wordIndex - 1].acf.hashtags
        ? this.state.words[this.state.wordIndex - 1].acf.hashtags.map(hash => {
            return hash.hashtag;
          })
        : [];
    }

    const scrollClasses = classnames('icon-scroll', { compact: this.delta });
    const meta =
      this.state.wordIndex > 0
        ? {
            excerpt: this.state.words[this.state.wordIndex - 1].acf.excerpt,
            image: this.state.words[this.state.wordIndex - 1].acf.image.sizes
              .large,
            title: this.state.words[this.state.wordIndex - 1].post_title
          }
        : {
            excerpt: this.state.data.post_excerpt,
            image: socialImage,
            title: this.state.data.post_title
          };

    return (
      <div className={classes}>
        <Helmet>
          <title>{config.TITLE} Words</title>
          <meta name="description" content={meta.excerpt} />
          <meta property="og:title" content={meta.title} />
          <meta property="og:url" content={window.location} />
          <meta property="og:image" content={meta.image} />
        </Helmet>
        <Scroll
          callback={this.scrollCallback}
          maxSlides={1}
          // scrollTarget=".word-list"
          target=".words-content"
        />

        <Loader loaded={this.state.loaded} />
        {this.state.loaded && <div className={scrollClasses} />}

        <div className="words-layout">
          <div className={wordsClasses}>
            <WordsPageInfo data={this.state.data} />
          </div>

          <div className={imageClasses}>
            {this.state.sideBlock && <SideBlock hex="#fff" />}
            {this.state.collapsed && (
              <div className="word-list__back" onClick={this.back}>
                <BackButton />
              </div>
            )}
            <div className="word-list">
              {this.state.words.map((word, index) => {
                return (
                  <WordItem
                    active={index + 1 === this.state.wordIndex}
                    data={word}
                    key={word.ID}
                    callback={() => this.changeWord(index + 1)}
                  />
                );
              })}
            </div>
          </div>
          {this.state.loaded && (
            <div id="wordsScroll" className={wordsDetailClasses}>
              <div className="words-detail__content">
                {wordsDetailContent && (
                  <Fragment>
                    <ImagePod
                      key={wordsDetailContent.post_title}
                      img={wordsDetailContent.image}
                      direction="top"
                      hex="#29abe2"
                    />
                    <div className="words-detail__meta">
                      <div className="words-detail__date">
                        {moment(wordsDetailContent.post_date).format(
                          'MM.DD.YY'
                        )}
                      </div>
                      <div className="words-detail__title">
                        {wordsDetailContent.post_title}
                      </div>
                      <div className="words-detail__posted-by">
                        Posted by: {wordsDetailContent.post_author}
                      </div>
                      <div className="words-detail__social">
                        <div className="words-detail__social-title">
                          SHARE THIS
                        </div>
                        <div className="words-detail__social-icons">
                          <FacebookShareButton
                            url={window.location.href}
                            quote={title}
                            hashtags={hashtags}
                          >
                            <FacebookIcon size={16} round={true} />
                          </FacebookShareButton>
                          <TwitterShareButton
                            url={window.location.href}
                            title={title}
                            hashtags={hashtags}
                          >
                            <TwitterIcon size={16} round={true} />
                          </TwitterShareButton>
                          <LinkedinShareButton url={window.location.href}>
                            <LinkedinIcon size={16} round={true} />
                          </LinkedinShareButton>
                        </div>
                      </div>
                    </div>
                    <div
                      className="words-detail__description"
                      dangerouslySetInnerHTML={{
                        __html: wordsDetailContent.post_content
                      }}
                    />
                  </Fragment>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

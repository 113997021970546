import React, { Component } from 'react';
import classnames from 'classnames';
import { Helmet } from 'react-helmet';
import API from '../services/api';
import { TweenMax, Power2 } from 'gsap/all';
import $ from 'jquery';
import { config, ReactGA } from '../config';

import WordsPageInfo from '../components/wordsPageInfo';
import Loader from '../components/loader';
import ProcessItem from '../components/processItem';
import BackButton from '../components/backButton';
import Scroll from '../helpers/scroll';
import ProcessDetailItem from '../components/processDetailItem';
import socialImage from '../images/social/process.jpg';

let mobile = false;

export default class Process extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: false,
      data: {
        acf: {
          slide: []
        }
      },
      loaded: false,
      sideBlock: false,
      slideNumber: 0,
      processIndex: -1,
      process: []
    };

    this.delta = 0;
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname);

    if ($(window).width() < 700) {
      mobile = true;
    }

    API.retrieveContent({ prefix: 'page', path: 'process' }).then(res => {
      this.setState({ data: res.data });
      setTimeout(() => {
        this.setState({ loaded: true });
      }, 20);
    });
  }

  changeProcessItem(index) {
    const interval = this.state.processIndex > -1 || mobile ? 0 : 1000;
    this.setState({ transitioning: false }, () => {
      if ($(window).outerWidth() > 700) {
        TweenMax.fromTo(
          '.words-page-title__container',
          0.5,
          {
            opacity: 1,
            y: 0
          },
          {
            opacity: 0,
            y: -20,
            ease: Power2.easeOut
          }
        );

        TweenMax.fromTo(
          '.words-page-description',
          0.5,
          {
            opacity: 1,
            y: 0
          },
          {
            opacity: 0,
            y: -20,
            ease: Power2.easeOut
          }
        );
      }

      setTimeout(() => {
        this.setState({ collapsed: true, processIndex: index }, () => {
          $('.main-content').animate(
            {
              scrollTop:
                $('#processScroll').offset().top -
                $('.content-container').offset().top
            },
            1000
          );
        });
      }, interval);
      setTimeout(() => {
        if ($('.process-detail__title').css('opacity') === '0') {
          TweenMax.fromTo(
            '.process-detail__title',
            1,
            {
              opacity: 0,
              y: 30
            },
            {
              opacity: 1,
              y: 0,
              ease: Power2.easeInOut
            }
          );
          TweenMax.fromTo(
            '.process-detail__description',
            1,
            {
              opacity: 0,
              y: 30
            },
            {
              opacity: 1,
              y: 0,
              ease: Power2.easeInOut
            }
          );
        }
      }, interval * 2);
    });
  }

  back = () => {
    this.setState({ collapsed: false });
    setTimeout(() => {
      this.setState({ processIndex: -1 });
      TweenMax.to($('.words-page-title__container'), 0.5, {
        opacity: 1,
        y: 0
      }).delay(0.5);

      TweenMax.to('.words-page-description', 0.5, {
        opacity: 1,
        y: 0
      }).delay(0.6);
    }, 500);
  };

  scrollCallback = index => {
    this.delta = index;

    this.setState({ slideNumber: index });
    if (index === 0) {
      TweenMax.staggerFromTo(
        '.process-list-item',
        0.3,
        {
          opacity: 1,
          y: 0,
          ease: Power2.easeOut
        },
        {
          opacity: 0,
          y: 30
        },

        0.1
      );
      setTimeout(() => {
        this.setState({ sideBlock: false });
      }, 500);
    }
    if (index === 1) {
      setTimeout(() => {
        TweenMax.staggerFromTo(
          '.process-list-item',
          0.3,
          {
            opacity: 0,
            y: 30
          },
          {
            opacity: 1,
            y: 0,
            ease: Power2.easeOut
          },
          0.1
        );
      }, 500);
    }
  };

  render() {
    const classes = classnames(
      { loaded: this.state.loaded },
      'page-process',
      'page'
    );

    const processClasses = classnames('process-content', {
      condensed: this.state.slideNumber === 1,
      collapsed: this.state.processIndex > 0,
      transitioning: this.state.transitioning
    });

    const imageClasses = classnames('process-container', {
      expanded: this.state.slideNumber === 1
    });

    const processDetailClasses = classnames('process-detail', {
      expanded: this.state.processIndex > 0
    });

    const scrollClasses = classnames('icon-scroll', { compact: this.delta });

    return (
      <div className={classes}>
        <Helmet>
          <title>{config.TITLE} Process</title>
          <meta name="description" content={this.state.data.post_excerpt} />
          <meta property="og:title" content={this.state.data.post_title} />
          <meta property="og:url" content={window.location} />
          <meta property="og:image" content={socialImage} />
        </Helmet>
        <Scroll
          callback={this.scrollCallback}
          maxSlides={1}
          scrollTarget=".process-list"
          target=".process-content"
        />

        <Loader loaded={this.state.loaded} />

        {this.state.loaded && <div className={scrollClasses} />}

        <div className="process-layout">
          <div className={processClasses}>
            <WordsPageInfo data={this.state.data} />
          </div>

          <div className={imageClasses}>
            {this.state.collapsed && (
              <div className="process-list__back" onClick={this.back}>
                <BackButton hex="#fff" />
              </div>
            )}
            <div className="process-list">
              {this.state.data.acf.slide.map((process, index) => {
                return (
                  <ProcessItem
                    active={index + 1 === this.state.processIndex}
                    callback={() => this.changeProcessItem(index + 1)}
                    data={process}
                    key={index}
                  />
                );
              })}
            </div>
          </div>
          {this.state.processIndex > 0 && (
            <div id="processScroll" className={processDetailClasses}>
              {this.state.data.acf.slide.map((slide, index) => {
                const className =
                  this.state.processIndex - 1 === index ? 'active' : '';
                return (
                  <ProcessDetailItem
                    className={className}
                    key={slide.background_image.ID}
                    title={slide.title}
                    description={slide.description}
                    image={slide.background_image.sizes.large}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }
}

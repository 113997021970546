import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  hex: PropTypes.string
}

function SideBlock(props) {
  return (
    <svg
      id="sideBlock"
      x="0px"
      y="0px"
      width="100%"
      height="100%"
      preserveAspectRatio="none"
      viewBox="0 0 400 768"
    >
      <g id="Scene_1_l1" width="100%" overflow="visible">
        <g style={{ display: 'none' }}>
          <path fill={props.hex} d="M0,768L400,768 400,0 0,0 0,768" />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.6s"
            keyTimes="0;1;1"
            values="none;inline;inline"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill={props.hex}
            d="M403.8,0L12.55,0Q-12.85,385.45 12.55,768L403.8,768 403.8,0"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.6s"
            keyTimes="0;0.947;1"
            values="none;inline;inline"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill={props.hex}
            d="M414.7,0L48.3,0Q-49.35,389.5 48.3,768L414.7,768 414.7,0"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.6s"
            keyTimes="0;0.895;0.947;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill={props.hex}
            d="M432.65,0L107.3,0Q-109.65,396.2 107.3,768L432.65,768 432.65,0"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.6s"
            keyTimes="0;0.842;0.895;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill={props.hex}
            d="M189.5,768L457.7,768 457.7,0 189.5,0Q-193.624,405.574 189.5,768"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.6s"
            keyTimes="0;0.789;0.842;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill={props.hex}
            d="M509.3,0L273.1,0Q-173.6,406.75 273.1,768L509.3,768 509.3,0"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.6s"
            keyTimes="0;0.737;0.789;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill={props.hex}
            d="M557.35,0L350.95,0Q-155,407.9 350.95,768L557.35,768 557.35,0"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.6s"
            keyTimes="0;0.684;0.737;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill={props.hex}
            d="M601.85,0L423,0Q-137.75,408.95 423,768L601.85,768 601.85,0"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.6s"
            keyTimes="0;0.632;0.684;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill={props.hex}
            d="M642.8,0L489.3,0Q-121.9,409.9 489.3,768L642.8,768 642.8,0"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.6s"
            keyTimes="0;0.579;0.632;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill={props.hex}
            d="M680.15,768L680.15,0 549.85,0Q-107.4,410.8 549.85,768L680.15,768"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.6s"
            keyTimes="0;0.526;0.579;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill={props.hex}
            d="M714,768L714,0 604.65,0Q-94.3,411.6 604.65,768L714,768"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.6s"
            keyTimes="0;0.474;0.526;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill={props.hex}
            d="M744.25,0L653.65,0Q-82.6,412.3 653.65,768L744.25,768 744.25,0"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.6s"
            keyTimes="0;0.421;0.474;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill={props.hex}
            d="M770.95,0L696.9,0Q-72.25,412.95 696.9,768L770.95,768 770.95,0"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.6s"
            keyTimes="0;0.368;0.421;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill={props.hex}
            d="M794.1,0L734.35,0Q-63.25,413.5 734.35,768L794.1,768 794.1,0"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.6s"
            keyTimes="0;0.316;0.368;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill={props.hex}
            d="M813.65,0L766.1,0Q-55.7,413.95 766.1,768L813.65,768 813.65,0"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.6s"
            keyTimes="0;0.263;0.316;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill={props.hex}
            d="M829.7,0L792.05,0Q-49.5,414.35 792.05,768L829.7,768 829.7,0"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.6s"
            keyTimes="0;0.211;0.263;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill={props.hex}
            d="M842.15,0L812.2,0Q-44.65,414.6 812.2,768L842.15,768 842.15,0"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.6s"
            keyTimes="0;0.158;0.211;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill={props.hex}
            d="M851.05,0L826.6,0Q-41.2,414.85 826.6,768L851.05,768 851.05,0"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.6s"
            keyTimes="0;0.105;0.158;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill={props.hex}
            d="M856.35,0L835.25,0Q-39.15,414.95 835.25,768L856.35,768 856.35,0"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.6s"
            keyTimes="0;0.053;0.105;1"
            values="none;inline;none;none"
          />
        </g>
        <g>
          <path
            fill={props.hex}
            d="M858.15,0L838.15,0Q-38.441,414.982 838.15,768L858.15,768 858.15,0"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.6s"
            keyTimes="0;0.053;1"
            values="inline;none;none"
          />
        </g>
      </g>
    </svg>
  );
}

SideBlock.propTypes = propTypes;
export default SideBlock;

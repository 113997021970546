import React, { Component, Fragment } from 'react';
import classnames from 'classnames';
import Slider from 'react-slick';
import Vimeo from '@u-wave/react-vimeo';
import $ from 'jquery';

class WorkSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slideIndex: 0,
      updateCount: 0,
      transitioning: false
    };
    this.slider = React.createRef();
  }

  componentDidMount() {
    $(window).on('resize', this.resizeHandler);
    this.resizeHandler();
  }

  resizeHandler = () => {
    const ww = $(window).outerWidth();
    if (ww > 700) {
      // Force the number to be different to ensure the componentDidUpdate fires in Vimeo lib
      const targetWidth = Math.round(ww / 2) + Math.random() * 1;
      const targetHeight = Math.round(targetWidth * 0.625) + Math.random() * 1;
      this.setState({ targetWidth, targetHeight });
    }
  };

  componentWillUnmount() {
    $(window).off('resize', this.resizeHandler);
  }

  changeSlide = index => {
    this.setState({ transitioning: true }, () => {
      setTimeout(() => {
        this.slider.slickGoTo(index, true);
        this.setState({ transitioning: false });
      }, 500);
    });

    if (this.props.callback) {
      this.props.callback(index);
    }
  };

  render() {
    const settings = {
      accessibility: false,
      arrows: false,
      dots: false,
      infinite: false,
      lazyLoad: 'progressive',
      mobileFirst: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipe: false,
      variableWidth: false,
      afterChange: () => {
        this.setState(state => ({ updateCount: state.updateCount + 1 }));
        this.resizeHandler();
      },
      beforeChange: (current, next) => {
        if (next < 0) {
          next = 0;
        }
        this.setState({ slideIndex: next });
        this.resizeHandler();
      }
    };

    return (
      <Fragment>
        <div className="work-slider__placeholder">
          <Slider
            ref={slider => (this.slider = slider)}
            className="content-slider"
            {...settings}
          >
            {this.props.data &&
              this.props.data.map((item, index) => {
                const slideClass = classnames('content-slide-item', {
                  active:
                    index === this.state.slideIndex && !this.state.transitioning
                });

                let workVideo = null;
                if (item.media_toggle !== 'Image') {
                  const createNode = htmlStr => {
                    const doc = new DOMParser().parseFromString(
                      htmlStr,
                      'text/html'
                    );
                    const element = doc.body.children[0];
                    return element;
                  };

                  const wv = createNode(item.work_video);
                  workVideo = wv.src;
                }

                const media =
                  item.media_toggle === 'Image' ? (
                    <div className={slideClass} key={item.title}>
                      <img src={item.work_image.sizes.large} alt={item.name} />
                    </div>
                  ) : (
                    <Vimeo
                      className={slideClass}
                      height={this.state.targetHeight}
                      key={index}
                      onEnd={this.props.videoStopCallback}
                      onReady={this.resizeHandler}
                      onPlay={this.props.videoPlayCallback}
                      onPause={this.props.videoStopCallback}
                      paused={index !== this.state.slideIndex}
                      video={workVideo}
                      width={this.state.targetWidth}
                    />
                  );

                return media;
              })}
          </Slider>
        </div>
        <div className="custom-dots__placeholder">
          <div className="custom-dots">
            {this.props.data &&
              this.props.data.map((item, index) => {
                const dotClasses = classnames('dot', {
                  active: index === this.state.slideIndex
                });
                return (
                  <div
                    key={item.title}
                    onClick={e => this.changeSlide(index)}
                    className={dotClasses}
                  >
                    <div className="dot-title">{item.title}</div>
                  </div>
                );
              })}
          </div>
        </div>
      </Fragment>
    );
  }
}

// WorkSlider.propTypes = propTypes;
export default WorkSlider;

import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import classnames from 'classnames';
import { config, ReactGA } from '../config';
import $ from 'jquery';

import API from '../services/api';
import Loader from '../components/loader';
import ContactInfo from '../components/contactInfo';
import ContactSlide from '../components/contactSlide';
import ContactSlideSubmit from '../components/contactSlideSubmit';
import socialImage from '../images/social/contact.jpg';
import { TweenMax } from 'gsap/all';

export default class Contact extends Component {
  state = {
    curSlide: 0,
    loaded: false,
    data: {
      acf: {
        contact_form: [],
        submit_text: "Let's continue the conversation"
      }
    },
    options: [],
    response: '',
    submitted: false,
    submitting: false,
    valid: false
  };

  componentDidMount() {
    ReactGA.pageview(window.location.pathname);

    API.retrieveContent({ prefix: 'page', path: 'contact' }).then(res => {
      this.setState({ data: res.data, loaded: true });
    });
  }

  selectItem = (title, index) => {
    const options = [...this.state.options];
    options[index] = {
      question: this.state.data.acf.contact_form[index]
        .contact_form_slide_title,
      response: title
    };

    this.setState({ options });
    this.moveToSlide(index);
  };

  moveToSlide(index) {
    this.setState({ curSlide: index + 1 });
  }

  goBack = index => {
    this.setState({ curSlide: index - 1 });
  };

  validate = el => {
    if ($('#email').val() !== '' && $('#name').val() !== '') {
      this.setState({ valid: true });
    } else {
      this.setState({ valid: false });
    }
  };

  submitForm = e => {
    this.setState({ submitting: true });
    e.preventDefault();
    let data = {
      Email: $('#email').val(),
      Name: $('#name').val(),
      Message: $('#message').val()
    };

    for (let i = 0, len = this.state.options.length; i < len; i++) {
      data[this.state.options[i].question] = this.state.options[i].response;
    }

    $.ajax({
      url: 'https://formcarry.com/s/wH_nNrChuao',
      method: 'POST',
      data: data,
      dataType: 'json',
      success: response => {
        if (response.status === 'success') {
          this.setState({
            submitting: false,
            submitted: true,
            response: 'success'
          });

          TweenMax.fromTo(
            '.page-title',
            0.5,
            {
              opacity: 0,
              y: 20
            },
            {
              opacity: 1,
              y: 0
            }
          );
        } else {
          this.setState({
            submitting: false,
            submitted: true,
            response: 'fail'
          });
        }
      }
    });
  };

  render() {
    const classes = classnames(
      { loaded: this.state.loaded },
      'page-contact',
      'page'
    );

    const successclasses = classnames('contact-form__success', {
      success: this.state.response === 'success'
    });
    return (
      <div className={classes}>
        <Helmet>
          <title>{config.TITLE} Contact</title>
          <meta name="description" content={this.state.data.post_excerpt} />
          <meta property="og:title" content={this.state.data.post_title} />
          <meta property="og:url" content={window.location} />
          <meta property="og:image" content={socialImage} />
        </Helmet>

        <Loader loaded={this.state.loaded} />
        {this.state.response !== 'success' && (
          <form className="contact-form">
            {this.state.loaded && !this.state.submitted && (
              <div className="contact-container">
                {this.state.data.acf.contact_form.map((slide, index) => {
                  const classname = classnames({
                    active: index === this.state.curSlide
                  });

                  return (
                    <ContactSlide
                      className={classname}
                      key={index}
                      answer={this.state.options[index]}
                      backCallback={this.goBack}
                      callback={this.selectItem}
                      data={slide}
                      slideNumber={index}
                    />
                  );
                })}

                <ContactSlideSubmit
                  backCallback={this.goBack}
                  callback={this.submitForm}
                  curSlide={this.state.curSlide}
                  emailPlaceholder={this.state.data.acf.email_placeholder}
                  messagePlaceholder={this.state.data.acf.message_placeholder}
                  namePlaceholder={this.state.data.acf.name_placeholder}
                  response={this.state.response}
                  slideNumber={this.state.data.acf.contact_form.length}
                  submitting={this.state.submitting}
                  title={this.state.data.acf.submit_text}
                  valid={this.state.valid}
                  validate={this.validate}
                />
              </div>
            )}
          </form>
        )}

        <div className={successclasses}>
          <div className="contact-page__success-title">
            {this.state.data.acf.success_title}
          </div>
          <div
            className="contact-page__success-description"
            dangerouslySetInnerHTML={{
              __html: this.state.data.acf.success_description
            }}
          />
        </div>

        <ContactInfo hex="#7aaedb" />
      </div>
    );
  }
}

import React, { Component } from 'react';
import classnames from 'classnames';
import { TweenMax } from 'gsap/all';
import PropTypes from 'prop-types';

const propTypes = {
  className: PropTypes.string,
  direction: PropTypes.string,
  hex: PropTypes.string,
  img: PropTypes.string.isRequired,
  index: PropTypes.number
};

class ImagePod extends Component {
  state = { firstLoad: true, loaded: false, prevImage: null };

  componentWillUpdate(nextProps) {
    if (nextProps.img !== this.props.img) {
      this.setState(
        {
          firstLoad: false,
          prevImage: this.props.img
        },
        () => {
          TweenMax.fromTo(
            `#image_${this.props.index}_cover`,
            1,
            {
              opacity: 1
            },
            {
              opacity: 0
            }
          );
        }
      );
    }
  }

  imageLoaded = () => {
    setTimeout(() => {
      this.setState({ loaded: true }, () => {
        if (this.state.firstLoad) {
          TweenMax.fromTo(
            `.image_${this.props.index}`,
            0.5,
            {
              opacity: 0,
              x: 0,
              y: 20
            },
            {
              opacity: 1,
              x: 0,
              y: 0
            }
          ).delay(0.5);
        }
      });
    }, this.props.index * 200);
  };

  render() {
    const imageClasses = classnames(
      'image-pod',
      this.props.className,
      this.props.direction,
      {
        loaded: this.state.loaded
      }
    );

    return (
      <div className={imageClasses}>
        {this.state.loaded && this.props.direction === 'right' && (
          <svg
            className="sideBlock"
            id={`sideBlock${this.props.index}`}
            x="0px"
            y="0px"
            width="100%"
            height="100%"
            preserveAspectRatio="none"
            viewBox="0 0 400 400"
          >
            <g id="Scene_1_l1" overflow="visible">
              <g style={{ display: 'none' }}>
                <path
                  fill={this.props.hex}
                  d="M0,400L400,400 400,0 0,0 0,400"
                />
                <animate
                  attributeName="display"
                  fill="freeze"
                  repeatCount="1"
                  dur="0.4s"
                  keyTimes="0;1;1"
                  values="none;inline;inline"
                />
              </g>
              <g style={{ display: 'none' }}>
                <path
                  fill={this.props.hex}
                  d="M403.8,0L12.55,0Q-12.85,200.75 12.55,400L403.8,400 403.8,0"
                />
                <animate
                  attributeName="display"
                  fill="freeze"
                  repeatCount="1"
                  dur="0.4s"
                  keyTimes="0;0.947;1"
                  values="none;inline;inline"
                />
              </g>
              <g style={{ display: 'none' }}>
                <path
                  fill={this.props.hex}
                  d="M414.7,0L48.3,0Q-49.35,202.85 48.3,400L414.7,400 414.7,0"
                />
                <animate
                  attributeName="display"
                  fill="freeze"
                  repeatCount="1"
                  dur="0.4s"
                  keyTimes="0;0.895;0.947;1"
                  values="none;inline;none;none"
                />
              </g>
              <g style={{ display: 'none' }}>
                <path
                  fill={this.props.hex}
                  d="M432.65,0L107.3,0Q-109.65,206.35 107.3,400L432.65,400 432.65,0"
                />
                <animate
                  attributeName="display"
                  fill="freeze"
                  repeatCount="1"
                  dur="0.4s"
                  keyTimes="0;0.842;0.895;1"
                  values="none;inline;none;none"
                />
              </g>
              <g style={{ display: 'none' }}>
                <path
                  fill={this.props.hex}
                  d="M189.5,400L457.7,400 457.7,0 189.5,0Q-193.6,211.2 189.5,400"
                />
                <animate
                  attributeName="display"
                  fill="freeze"
                  repeatCount="1"
                  dur="0.4s"
                  keyTimes="0;0.789;0.842;1"
                  values="none;inline;none;none"
                />
              </g>
              <g style={{ display: 'none' }}>
                <path
                  fill={this.props.hex}
                  d="M509.3,0L273.1,0Q-173.6,211.85 273.1,400L509.3,400 509.3,0"
                />
                <animate
                  attributeName="display"
                  fill="freeze"
                  repeatCount="1"
                  dur="0.4s"
                  keyTimes="0;0.737;0.789;1"
                  values="none;inline;none;none"
                />
              </g>
              <g style={{ display: 'none' }}>
                <path
                  fill={this.props.hex}
                  d="M557.35,0L350.95,0Q-155,212.45 350.95,400L557.35,400 557.35,0"
                />
                <animate
                  attributeName="display"
                  fill="freeze"
                  repeatCount="1"
                  dur="0.4s"
                  keyTimes="0;0.684;0.737;1"
                  values="none;inline;none;none"
                />
              </g>
              <g style={{ display: 'none' }}>
                <path
                  fill={this.props.hex}
                  d="M601.85,0L423,0Q-137.75,213 423,400L601.85,400 601.85,0"
                />
                <animate
                  attributeName="display"
                  fill="freeze"
                  repeatCount="1"
                  dur="0.4s"
                  keyTimes="0;0.632;0.684;1"
                  values="none;inline;none;none"
                />
              </g>
              <g style={{ display: 'none' }}>
                <path
                  fill={this.props.hex}
                  d="M642.8,0L489.3,0Q-121.9,213.5 489.3,400L642.8,400 642.8,0"
                />
                <animate
                  attributeName="display"
                  fill="freeze"
                  repeatCount="1"
                  dur="0.4s"
                  keyTimes="0;0.579;0.632;1"
                  values="none;inline;none;none"
                />
              </g>
              <g style={{ display: 'none' }}>
                <path
                  fill={this.props.hex}
                  d="M680.15,400L680.15,0 549.85,0Q-107.4,213.95 549.85,400L680.15,400"
                />
                <animate
                  attributeName="display"
                  fill="freeze"
                  repeatCount="1"
                  dur="0.4s"
                  keyTimes="0;0.526;0.579;1"
                  values="none;inline;none;none"
                />
              </g>
              <g style={{ display: 'none' }}>
                <path
                  fill={this.props.hex}
                  d="M714,400L714,0 604.65,0Q-94.3,214.35 604.65,400L714,400"
                />
                <animate
                  attributeName="display"
                  fill="freeze"
                  repeatCount="1"
                  dur="0.4s"
                  keyTimes="0;0.474;0.526;1"
                  values="none;inline;none;none"
                />
              </g>
              <g style={{ display: 'none' }}>
                <path
                  fill={this.props.hex}
                  d="M744.25,0L653.65,0Q-82.6,214.75 653.65,400L744.25,400 744.25,0"
                />
                <animate
                  attributeName="display"
                  fill="freeze"
                  repeatCount="1"
                  dur="0.4s"
                  keyTimes="0;0.421;0.474;1"
                  values="none;inline;none;none"
                />
              </g>
              <g style={{ display: 'none' }}>
                <path
                  fill={this.props.hex}
                  d="M770.95,0L696.9,0Q-72.25,215.05 696.9,400L770.95,400 770.95,0"
                />
                <animate
                  attributeName="display"
                  fill="freeze"
                  repeatCount="1"
                  dur="0.4s"
                  keyTimes="0;0.368;0.421;1"
                  values="none;inline;none;none"
                />
              </g>
              <g style={{ display: 'none' }}>
                <path
                  fill={this.props.hex}
                  d="M794.1,0L734.35,0Q-63.25,215.35 734.35,400L794.1,400 794.1,0"
                />
                <animate
                  attributeName="display"
                  fill="freeze"
                  repeatCount="1"
                  dur="0.4s"
                  keyTimes="0;0.316;0.368;1"
                  values="none;inline;none;none"
                />
              </g>
              <g style={{ display: 'none' }}>
                <path
                  fill={this.props.hex}
                  d="M813.65,0L766.1,0Q-55.7,215.6 766.1,400L813.65,400 813.65,0"
                />
                <animate
                  attributeName="display"
                  fill="freeze"
                  repeatCount="1"
                  dur="0.4s"
                  keyTimes="0;0.263;0.316;1"
                  values="none;inline;none;none"
                />
              </g>
              <g style={{ display: 'none' }}>
                <path
                  fill={this.props.hex}
                  d="M829.7,0L792.05,0Q-49.5,215.8 792.05,400L829.7,400 829.7,0"
                />
                <animate
                  attributeName="display"
                  fill="freeze"
                  repeatCount="1"
                  dur="0.4s"
                  keyTimes="0;0.211;0.263;1"
                  values="none;inline;none;none"
                />
              </g>
              <g style={{ display: 'none' }}>
                <path
                  fill={this.props.hex}
                  d="M842.15,0L812.2,0Q-44.65,215.95 812.2,400L842.15,400 842.15,0"
                />
                <animate
                  attributeName="display"
                  fill="freeze"
                  repeatCount="1"
                  dur="0.4s"
                  keyTimes="0;0.158;0.211;1"
                  values="none;inline;none;none"
                />
              </g>
              <g style={{ display: 'none' }}>
                <path
                  fill={this.props.hex}
                  d="M851.05,0L826.6,0Q-41.2,216.05 826.6,400L851.05,400 851.05,0"
                />
                <animate
                  attributeName="display"
                  fill="freeze"
                  repeatCount="1"
                  dur="0.4s"
                  keyTimes="0;0.105;0.158;1"
                  values="none;inline;none;none"
                />
              </g>
              <g style={{ display: 'none' }}>
                <path
                  fill={this.props.hex}
                  d="M856.35,0L835.25,0Q-39.15,216.15 835.25,400L856.35,400 856.35,0"
                />
                <animate
                  attributeName="display"
                  fill="freeze"
                  repeatCount="1"
                  dur="0.4s"
                  keyTimes="0;0.053;0.105;1"
                  values="none;inline;none;none"
                />
              </g>
              <g>
                <path
                  fill={this.props.hex}
                  d="M858.15,0L838.15,0Q-38.45,216.15 838.15,400L858.15,400 858.15,0"
                />
                <animate
                  attributeName="display"
                  fill="freeze"
                  repeatCount="1"
                  dur="0.4s"
                  keyTimes="0;0.053;1"
                  values="inline;none;none"
                />
              </g>
            </g>
          </svg>
        )}

        {this.state.loaded && this.props.direction === 'top' && (
          <svg
            className="sideBlock"
            id={`sideBlock${this.props.index}`}
            x="0px"
            y="0px"
            width="100%"
            height="100%"
            preserveAspectRatio="none"
            viewBox="0 0 400 400"
          >
            <g id="Scene_1_l1" overflow="visible">
              <g style={{ display: 'none' }}>
                <path
                  fill={this.props.hex}
                  d="M0,400L400,400 400,0 0,0 0,400"
                />
                <animate
                  attributeName="display"
                  fill="freeze"
                  repeatCount="1"
                  dur="0.4s"
                  keyTimes="0;1;1"
                  values="none;inline;inline"
                />
              </g>
              <g style={{ display: 'none' }}>
                <path
                  fill={this.props.hex}
                  d="M400,-7.55L0,-7.55 0,383.7Q200.75,409.1 400,383.7L400,-7.55"
                />
                <animate
                  attributeName="display"
                  fill="freeze"
                  repeatCount="1"
                  dur="0.4s"
                  keyTimes="0;0.947;1"
                  values="none;inline;inline"
                />
              </g>
              <g style={{ display: 'none' }}>
                <path
                  fill={this.props.hex}
                  d="M400,-29.05L0,-29.05 0,337.35Q202.85,435.05 400,337.35L400,-29.05"
                />
                <animate
                  attributeName="display"
                  fill="freeze"
                  repeatCount="1"
                  dur="0.4s"
                  keyTimes="0;0.895;0.947;1"
                  values="none;inline;none;none"
                />
              </g>
              <g style={{ display: 'none' }}>
                <path
                  fill={this.props.hex}
                  d="M400,-64.5L0,-64.5 0,260.85Q206.35,477.8 400,260.85L400,-64.5"
                />
                <animate
                  attributeName="display"
                  fill="freeze"
                  repeatCount="1"
                  dur="0.4s"
                  keyTimes="0;0.842;0.895;1"
                  values="none;inline;none;none"
                />
              </g>
              <g style={{ display: 'none' }}>
                <path
                  fill={this.props.hex}
                  d="M400,-113.9L0,-113.9 0,154.3Q211.2,537.4 400,154.3L400,-113.9"
                />
                <animate
                  attributeName="display"
                  fill="freeze"
                  repeatCount="1"
                  dur="0.4s"
                  keyTimes="0;0.789;0.842;1"
                  values="none;inline;none;none"
                />
              </g>
              <g style={{ display: 'none' }}>
                <path
                  fill={this.props.hex}
                  d="M400,-158.65L0,-158.65 0,77.55Q211.85,524.25 400,77.55L400,-158.65"
                />
                <animate
                  attributeName="display"
                  fill="freeze"
                  repeatCount="1"
                  dur="0.4s"
                  keyTimes="0;0.737;0.789;1"
                  values="none;inline;none;none"
                />
              </g>
              <g style={{ display: 'none' }}>
                <path
                  fill={this.props.hex}
                  d="M400,-200.35L0,-200.35 0,6.1Q212.45,512 400,6.1L400,-200.35"
                />
                <animate
                  attributeName="display"
                  fill="freeze"
                  repeatCount="1"
                  dur="0.4s"
                  keyTimes="0;0.684;0.737;1"
                  values="none;inline;none;none"
                />
              </g>
              <g style={{ display: 'none' }}>
                <path
                  fill={this.props.hex}
                  d="M0,-238.95L0,-60.1Q213,500.65 400,-60.1L400,-238.95 0,-238.95"
                />
                <animate
                  attributeName="display"
                  fill="freeze"
                  repeatCount="1"
                  dur="0.4s"
                  keyTimes="0;0.632;0.684;1"
                  values="none;inline;none;none"
                />
              </g>
              <g style={{ display: 'none' }}>
                <path
                  fill={this.props.hex}
                  d="M0,-274.45L0,-120.95Q213.5,490.25 400,-120.95L400,-274.45 0,-274.45"
                />
                <animate
                  attributeName="display"
                  fill="freeze"
                  repeatCount="1"
                  dur="0.4s"
                  keyTimes="0;0.579;0.632;1"
                  values="none;inline;none;none"
                />
              </g>
              <g style={{ display: 'none' }}>
                <path
                  fill={this.props.hex}
                  d="M0,-306.85L0,-176.55Q213.95,480.7 400,-176.55L400,-306.85 0,-306.85"
                />
                <animate
                  attributeName="display"
                  fill="freeze"
                  repeatCount="1"
                  dur="0.4s"
                  keyTimes="0;0.526;0.579;1"
                  values="none;inline;none;none"
                />
              </g>
              <g style={{ display: 'none' }}>
                <path
                  fill={this.props.hex}
                  d="M0,-336.2L0,-226.85Q214.35,472.1 400,-226.85L400,-336.2 0,-336.2"
                />
                <animate
                  attributeName="display"
                  fill="freeze"
                  repeatCount="1"
                  dur="0.4s"
                  keyTimes="0;0.474;0.526;1"
                  values="none;inline;none;none"
                />
              </g>
              <g style={{ display: 'none' }}>
                <path
                  fill={this.props.hex}
                  d="M0,-362.45L0,-271.85Q214.75,464.4 400,-271.85L400,-362.45 0,-362.45"
                />
                <animate
                  attributeName="display"
                  fill="freeze"
                  repeatCount="1"
                  dur="0.4s"
                  keyTimes="0;0.421;0.474;1"
                  values="none;inline;none;none"
                />
              </g>
              <g style={{ display: 'none' }}>
                <path
                  fill={this.props.hex}
                  d="M0,-385.6L0,-311.55Q215.05,457.55 400,-311.55L400,-385.6 0,-385.6"
                />
                <animate
                  attributeName="display"
                  fill="freeze"
                  repeatCount="1"
                  dur="0.4s"
                  keyTimes="0;0.368;0.421;1"
                  values="none;inline;none;none"
                />
              </g>
              <g style={{ display: 'none' }}>
                <path
                  fill={this.props.hex}
                  d="M400,-405.7L0,-405.7 0,-345.95Q215.35,451.7 400,-345.95L400,-405.7"
                />
                <animate
                  attributeName="display"
                  fill="freeze"
                  repeatCount="1"
                  dur="0.4s"
                  keyTimes="0;0.316;0.368;1"
                  values="none;inline;none;none"
                />
              </g>
              <g style={{ display: 'none' }}>
                <path
                  fill={this.props.hex}
                  d="M400,-422.65L0,-422.65 0,-375.1Q215.6,446.7 400,-375.1L400,-422.65"
                />
                <animate
                  attributeName="display"
                  fill="freeze"
                  repeatCount="1"
                  dur="0.4s"
                  keyTimes="0;0.263;0.316;1"
                  values="none;inline;none;none"
                />
              </g>
              <g style={{ display: 'none' }}>
                <path
                  fill={this.props.hex}
                  d="M400,-398.9L400,-436.55 0,-436.55 0,-398.9Q215.8,442.6 400,-398.9"
                />
                <animate
                  attributeName="display"
                  fill="freeze"
                  repeatCount="1"
                  dur="0.4s"
                  keyTimes="0;0.211;0.263;1"
                  values="none;inline;none;none"
                />
              </g>
              <g style={{ display: 'none' }}>
                <path
                  fill={this.props.hex}
                  d="M400,-417.45L400,-447.35 0,-447.35 0,-417.45Q215.95,439.45 400,-417.45"
                />
                <animate
                  attributeName="display"
                  fill="freeze"
                  repeatCount="1"
                  dur="0.4s"
                  keyTimes="0;0.158;0.211;1"
                  values="none;inline;none;none"
                />
              </g>
              <g style={{ display: 'none' }}>
                <path
                  fill={this.props.hex}
                  d="M400,-430.65L400,-455.1 0,-455.1 0,-430.65Q216.05,437.15 400,-430.65"
                />
                <animate
                  attributeName="display"
                  fill="freeze"
                  repeatCount="1"
                  dur="0.4s"
                  keyTimes="0;0.105;0.158;1"
                  values="none;inline;none;none"
                />
              </g>
              <g style={{ display: 'none' }}>
                <path
                  fill={this.props.hex}
                  d="M400,-438.6L400,-459.7 0,-459.7 0,-438.6Q216.15,435.8 400,-438.6"
                />
                <animate
                  attributeName="display"
                  fill="freeze"
                  repeatCount="1"
                  dur="0.4s"
                  keyTimes="0;0.053;0.105;1"
                  values="none;inline;none;none"
                />
              </g>
              <g>
                <path
                  fill={this.props.hex}
                  d="M400,-441.25L400,-461.25 0,-461.25 0,-441.25Q216.15,435.35 400,-441.25"
                />
                <animate
                  attributeName="display"
                  fill="freeze"
                  repeatCount="1"
                  dur="0.4s"
                  keyTimes="0;0.053;1"
                  values="inline;none;none"
                />
              </g>
            </g>
          </svg>
        )}
        <img
          id={`image_${this.props.index}_cover`}
          key={`image_${this.props.index}_cover`}
          className="image-cover"
          src={this.state.prevImage}
          alt=""
        />

        <img
          key=""
          className={`image_${this.props.index}`}
          onLoad={this.imageLoaded}
          src={this.props.img}
          alt=""
        />
      </div>
    );
  }
}

ImagePod.propTypes = propTypes;
export default ImagePod;

import React, { Component } from 'react';
import MorphingBG from './morphingBG';

class Background extends Component {
  componentDidMount() {
    new MorphingBG(document.querySelector('svg.background'));
  }

  render() {
    return (
      <svg
        className="background"
        x="0px"
        y="0px"
        width="100%"
        height="100%"
        viewBox="0 0 1333.477 749.693"
        enableBackground="new 0 0 1333.477 749.693"
        preserveAspectRatio="none"
      >
        <path
          id="layer12_end"
          visibility="hidden"
          display="inline"
          fill="#FFFF00"
          d="M1361.92,564.68c-10.922,3.879-22.924-589.92-22.924-589.92l-315.53,3.831l49.893,383.064
		C1073.358,361.655,1364.616,563.722,1361.92,564.68"
        />
        <path
          id="layer11_end"
          visibility="hidden"
          display="inline"
          fill="#FCEE21"
          d="M1093.993-30.889l-449.814,8.33c0,0,0,827.437,4.265,827.437
		c0.1,0,0.107,0.182,703.776-30.543c15.545-146.311-96.815-405.367-173.545-486.747
		C1132.86,238.997,1077.403,116.846,1093.993-30.889"
        />
        <path
          id="layer10_end"
          visibility="hidden"
          display="inline"
          fill="#FFFF00"
          d="M1021.801-29.5l-516.454,6.942c0,0,0,827.437,4.265,827.437
		c4.264,0,735.07-55.185,735.07-55.185c15.546-146.311-198.532-391.584-221.216-501.109
		C999.231,131.572,1005.211,118.234,1021.801-29.5"
        />
        <path
          id="layer9_end"
          visibility="hidden"
          display="inline"
          fill="#D9E021"
          d="M999.231-35.054l-563.3,12.495c0,0,0,827.437,4.265,827.437
		s674.332-30.543,674.332-30.543c15.546-146.311-80.796-290.912-130.872-390.924C919.553,255.382,960.065,83.567,999.231-35.054"
        />
        <path
          id="layer8_end"
          visibility="hidden"
          display="inline"
          fill="#FCEE21"
          d="M954.226-38.057L280.44-22.559c0,0,0,827.437,4.265,827.437
		s671.523-30.543,671.523-30.543c15.546-146.311-19.941-292.393-38.647-402.666c-23.541-138.777,22.058-258.988,38.647-406.723"
        />
        <path
          id="layer7_end"
          visibility="hidden"
          display="inline"
          fill="#FFFF00"
          d="M929.65-35.054L155.491-22.559c0,0,0,827.437,4.265,827.437
		c4.264,0,571.886-30.543,571.886-30.543c77.996-135.712,108.467-287.19,101.998-399.488
		C825.407,231.904,913.061,112.681,929.65-35.054"
        />
        <path
          id="layer6_end"
          visibility="hidden"
          display="inline"
          fill="#FCEE21"
          d="M809.639-3.586L118.007-22.559c0,0,0,827.437,4.265,827.437
		c4.264,0,544.466-55.185,544.466-55.185c15.546-146.311,25.236-250.615,40.89-362.005
		C739.825,158.575,793.049,144.148,809.639-3.586"
        />
        <path
          id="layer5_end"
          visibility="hidden"
          display="inline"
          fill="#D9E021"
          d="M739.825-29.5l-719,6.942c0,0,0,827.437,4.265,827.437
		c4.264,0,562.526-55.185,562.526-55.185c15.547-146.311,13.291-224.412,26.714-336.093
		C644.622,161.575,723.235,118.234,739.825-29.5"
        />
        <path
          id="layer4_end"
          visibility="hidden"
          display="inline"
          fill="#8CC63F"
          d="M614.33-35.054L20.825-22.559c0,0,0,827.437,4.265,827.437
		c4.264,0,229.493-34.242,229.493-34.242c10.486-98.678,215.086-232.05,261.026-321.031
		C644.179,200.579,592.401,160.222,614.33-35.054"
        />
        <path
          id="layer3_end"
          visibility="hidden"
          display="inline"
          fill="#D9E021"
          d="M554.758-53.622L-84.294-15.908l58.465,823.053l64.39-57.452
		C-17.398,585.404,170.944,492.542,280.823,326.52C443.602,80.567,565.572,102.865,554.758-53.622"
        />
        <path
          id="layer2_end"
          visibility="hidden"
          display="inline"
          fill="#FCEE21"
          d="M235.568,0L-42.156-3.586l38.041,660.211C15.399,173.818,230.203,340.574,235.568,0"
        />
        <path
          id="layer1_end"
          visibility="hidden"
          display="inline"
          fill="#FCEE21"
          d="M1114.527-8.7c1.678,140.272,78.175,278.429,222.855,241.591
		c0,0,6.282-248.778-2.956-247.027C1325.188-12.384,1114.527-8.7,1114.527-8.7"
        />
        <path
          id="layer12"
          className="background-path"
          fill="#FFFF00"
          d="M1361.92,564.68c-10.922,3.879-22.924-589.92-22.924-589.92l-315.53,3.831l49.893,383.064
		C1073.358,361.655,1364.616,563.722,1361.92,564.68"
        />
        <path
          id="layer11"
          className="background-path"
          fill="#FCEE21"
          d="M1093.993-30.889l-449.814,8.33c0,0,0,827.437,4.265,827.437c0.1,0,0.107,0.182,703.776-30.543
		c15.545-146.311-59.325-430.909-136.055-512.289C1170.351,213.455,1077.403,116.846,1093.993-30.889"
        />
        <path
          id="layer10"
          className="background-path"
          fill="#FFFF00"
          d="M1021.801-29.5l-516.454,6.942c0,0,0,827.437,4.265,827.437c4.264,0,703.776-30.543,703.776-30.543
		c15.546-146.311-191.259-415.012-136.055-512.289C1130.089,169.083,1005.211,118.234,1021.801-29.5"
        />
        <path
          id="layer9"
          className="background-path"
          fill="#D9E021"
          d="M982.928-35.054L435.931-22.559c0,0,0,827.437,4.265,827.437s641.302-30.543,641.302-30.543
		c15.546-146.311-23.505-302.653-73.581-402.666C943.814,243.641,966.338,112.681,982.928-35.054"
        />
        <path
          id="layer8"
          className="background-path"
          fill="#FCEE21"
          d="M948.22-35.054L280.44-22.559c0,0,0,827.437,4.265,827.437s530.237-30.543,530.237-30.543
		c15.546-146.311,152.715-302.653,102.639-402.666C853.479,243.641,931.63,112.681,948.22-35.054"
        />
        <path
          id="layer7"
          className="background-path"
          fill="#FFFF00"
          d="M899.629-35.054L155.491-22.559c0,0,0,827.437,4.265,827.437c4.264,0,571.886-30.543,571.886-30.543
		c15.546-146.311,67.458-290.368,60.989-402.666C784.398,228.727,883.039,112.681,899.629-35.054"
        />
        <path
          id="layer6"
          className="background-path"
          fill="#FCEE21"
          d="M862.145-35.054L118.007-22.559c0,0,0,827.437,4.265,827.437c4.264,0,592.71-30.543,592.71-30.543
		c15.546-146.311,46.634-290.368,40.165-402.666C746.914,228.727,845.555,112.681,862.145-35.054"
        />
        <path
          id="layer5"
          className="background-path"
          fill="#D9E021"
          d="M806.611-35.054L20.825-22.559c0,0,0,827.437,4.265,827.437c4.264,0,460.259-30.543,460.259-30.543
		c15.547-146.311,165.202-290.368,158.734-402.666C635.849,228.727,790.021,112.681,806.611-35.054"
        />
        <path
          id="layer4"
          className="background-path"
          fill="#8CC63F"
          d="M674.722-35.054L20.825-22.559c0,0,0,827.437,4.265,827.437c4.264,0,328.369-16.66,328.369-16.66
		c10.486-98.678,63.843-184.033,90.626-280.526C484.522,362.005,652.792,160.222,674.722-35.054"
        />
        <path
          id="layer3"
          className="background-path"
          fill="#D9E021"
          d="M554.758-53.622L-84.294-15.908l58.465,823.053l152.195-16.788
		C70.406,626.068,376.797,417.219,417.784,222.394C442.771,103.616,565.572,102.865,554.758-53.622"
        />
        <path
          id="layer2"
          className="background-path"
          fill="#FCEE21"
          d="M304.216-29L-42.156-3.586l38.041,722.396C15.399,236.004,298.851,311.573,304.216-29"
        />
        <path
          id="layer1"
          className="background-path"
          fill="#FCEE21"
          d="M1114.527-8.7c38.112,151.395,112.459,223.485,222.855,241.591c0,0,6.282-248.778-2.956-247.027
		C1325.188-12.384,1114.527-8.7,1114.527-8.7"
        />

        <rect
          id="SVGID_7_"
          className="color-multiply"
          x="0"
          y="0"
          fill="#934ABB"
          width="100%"
          height="100%"
        />

        <rect
          className="color-filter"
          x="0"
          y="0"
          fill="#934ABB"
          width="100%"
          height="100%"
        />
      </svg>
    );
  }
}

export default Background;

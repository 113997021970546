import React, { Component, Fragment } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import ReactGA from 'react-ga';
import history from '../src/helpers/history';
import classnames from 'classnames';
import {
  About,
  Alignment,
  Contact,
  Home,
  NoMatch,
  Work,
  WorkDetail,
  Process,
  Words
} from './containers';
import Navigation from './components/navigation';
import BackgroundAbout from './backgrounds/about';
import BackgroundAlignment from './backgrounds/alignment';
import BackgroundContact from './backgrounds/contact';
import BackgroundProcess from './backgrounds/process';
import BackgroundWords from './backgrounds/words';
import BackgroundWork from './backgrounds/work';

import Transitions from '../src/transitions';
import Lottie from 'react-lottie';

ReactGA.initialize('UA-134226489-1');

let TransitionComponent = null;

const pageColorLookup = {
  about: 'green',
  work: 'pink',
  process: 'purple',
  alignment: 'blue',
  writing: 'green',
  contact: 'blue'
};

class App extends Component {
  state = { transitioning: true, videoStatus: null };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ transitioning: false });
    }, 1000);
  }

  updateVideoStatue;

  navigate = (event, page) => {
    TransitionComponent =
      Transitions[Math.floor(Math.random() * Transitions.length)];

    const pageTarget = pageColorLookup[page.split('/')[1]] || 'pink';

    this.setState({ transitioning: true, page, pageTarget }, () => {
      setTimeout(() => {
        this.open = false;
        this.forceUpdate();
        history.push(page);
      }, 500);
      setTimeout(() => {
        this.setState({ transitioning: false });
      }, 1200);
    });
    event.preventDefault();
  };

  updateVideoStatus = status => {
    this.setState({ videoStatus: status });
  };

  render() {
    const contentClass = classnames('main-content', {
      home: window.location.pathname === '/'
    });
    const lottieClass = classnames('transition', this.state.pageTarget);
    const animationOptions = {
      loop: true,
      autoplay: true,
      animationData:
        TransitionComponent != null ? TransitionComponent.default : '',
      rendererSettings: {
        className: lottieClass,
        preserveAspectRatio: 'none'
      }
    };

    return (
      <Fragment>
        <div className="transition">
          {this.state.transitioning && TransitionComponent != null && (
            <Lottie options={animationOptions} />
          )}
        </div>
        <Navigation
          open={this.open}
          navigate={this.navigate}
          history={history}
        />
        <Router history={history}>
          <Switch>
            <Route path="/about/" component={BackgroundAbout} />
            <Route path="/alignment/" component={BackgroundAlignment} />
            <Route path="/contact/" component={BackgroundContact} />
            <Route
              path="/work/"
              render={props => (
                <BackgroundWork
                  {...props}
                  status={this.state.videoStatus}
                  updateVideoStatus={this.updateVideoStatus}
                />
              )}
            />

            <Route path="/process/" component={BackgroundProcess} />
            <Route path="/writing/:slug?" component={BackgroundWords} />
          </Switch>
        </Router>
        <div className={contentClass}>
          <Router history={history}>
            <div className="content-container">
              <Switch>
                <Route
                  path="/"
                  exact
                  render={props => <Home {...props} navigate={this.navigate} />}
                />
                <Route path="/about/" component={About} />
                <Route path="/alignment/" component={Alignment} />
                <Route path="/contact/" component={Contact} />
                <Route
                  path="/work/"
                  exact={true}
                  render={props => <Work {...props} navigate={this.navigate} />}
                />
                <Route
                  path="/work/:slug"
                  render={props => (
                    <WorkDetail
                      {...props}
                      updateVideoStatus={this.updateVideoStatus}
                      navigate={this.navigate}
                    />
                  )}
                />
                <Route path="/process/" component={Process} />
                <Route path="/writing/:slug?" component={Words} />
                <Route component={NoMatch} />
              </Switch>
            </div>
          </Router>
        </div>
      </Fragment>
    );
  }
}

export default App;

import React, { Component } from 'react';
import MorphingBG from './morphingBG';

class Background extends Component {
  componentDidMount() {
    new MorphingBG(document.querySelector('svg.background'));
  }

  render() {
    return (
      <svg
        className="background"
        x="0px"
        y="0px"
        width="100%"
        height="100%"
        viewBox="0 0 1333.477 749.693"
        enableBackground="new 0 0 1333.477 749.693"
        preserveAspectRatio="none"
      >
        <defs>
          <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#0E9E82" />
            <stop offset="100%" stopColor="#0D83A0" />
          </linearGradient>
        </defs>

        <path
          id="layer12_end"
          visibility="hidden"
          display="inline"
          fill="#FFFF00"
          d="M1361.92,564.68c-10.922,3.879-22.924-589.92-22.924-589.92l-315.53,3.831l49.893,383.064
		C1073.358,361.655,1364.616,563.722,1361.92,564.68"
        />
        <path
          id="layer11_end"
          visibility="hidden"
          display="inline"
          fill="#FCEE21"
          d="M1093.993-30.889l-449.814,8.33c0,0,0,827.437,4.265,827.437
		c0.1,0,0.107,0.182,703.776-30.543c15.545-146.311-96.815-405.367-173.545-486.747
		C1132.86,238.997,1077.403,116.846,1093.993-30.889"
        />
        <path
          id="layer10_end"
          visibility="hidden"
          display="inline"
          fill="#FFFF00"
          d="M1021.801-29.5l-516.454,6.942c0,0,0,827.437,4.265,827.437
		c4.264,0,735.07-55.185,735.07-55.185c15.546-146.311-198.532-391.584-221.216-501.109
		C999.231,131.572,1005.211,118.234,1021.801-29.5"
        />
        <path
          id="layer9_end"
          visibility="hidden"
          display="inline"
          fill="#D9E021"
          d="M999.231-35.054l-563.3,12.495c0,0,0,827.437,4.265,827.437
		s674.332-30.543,674.332-30.543c15.546-146.311-80.796-290.912-130.872-390.924C919.553,255.382,960.065,83.567,999.231-35.054"
        />
        <path
          id="layer8_end"
          visibility="hidden"
          display="inline"
          fill="#FCEE21"
          d="M954.226-38.057L280.44-22.559c0,0,0,827.437,4.265,827.437
		s671.523-30.543,671.523-30.543c15.546-146.311-19.941-292.393-38.647-402.666c-23.541-138.777,22.058-258.988,38.647-406.723"
        />
        <path
          id="layer7_end"
          visibility="hidden"
          display="inline"
          fill="#FFFF00"
          d="M929.65-35.054L155.491-22.559c0,0,0,827.437,4.265,827.437
		c4.264,0,571.886-30.543,571.886-30.543c77.996-135.712,108.467-287.19,101.998-399.488
		C825.407,231.904,913.061,112.681,929.65-35.054"
        />
        <path
          id="layer6_end"
          visibility="hidden"
          display="inline"
          fill="#FCEE21"
          d="M809.639-3.586L118.007-22.559c0,0,0,827.437,4.265,827.437
		c4.264,0,544.466-55.185,544.466-55.185c15.546-146.311,25.236-250.615,40.89-362.005
		C739.825,158.575,793.049,144.148,809.639-3.586"
        />
        <path
          id="layer5_end"
          visibility="hidden"
          display="inline"
          fill="#D9E021"
          d="M739.825-29.5l-719,6.942c0,0,0,827.437,4.265,827.437
		c4.264,0,562.526-55.185,562.526-55.185c15.547-146.311,13.291-224.412,26.714-336.093
		C644.622,161.575,723.235,118.234,739.825-29.5"
        />
        <path
          id="layer4_end"
          visibility="hidden"
          display="inline"
          fill="#8CC63F"
          d="M614.33-35.054L20.825-22.559c0,0,0,827.437,4.265,827.437
		c4.264,0,229.493-34.242,229.493-34.242c10.486-98.678,215.086-232.05,261.026-321.031
		C644.179,200.579,592.401,160.222,614.33-35.054"
        />
        <path
          id="layer3_end"
          visibility="hidden"
          display="inline"
          fill="#D9E021"
          d="M554.758-53.622L-84.294-15.908l58.465,823.053l64.39-57.452
		C-17.398,585.404,170.944,492.542,280.823,326.52C443.602,80.567,565.572,102.865,554.758-53.622"
        />
        <path
          id="layer2_end"
          visibility="hidden"
          display="inline"
          fill="#FCEE21"
          d="M235.568,0L-42.156-3.586l38.041,660.211C15.399,173.818,230.203,340.574,235.568,0"
        />
        <path
          id="layer1_end"
          visibility="hidden"
          display="inline"
          fill="#FCEE21"
          d="M1114.527-8.7c1.678,140.272,78.175,278.429,222.855,241.591
		c0,0,6.282-248.778-2.956-247.027C1325.188-12.384,1114.527-8.7,1114.527-8.7"
        />
        <path
          id="layer12"
          className="background-path"
          fill="#e3e3e3"
          d="M1361.92,564.68c-10.922,3.879-22.924-589.92-22.924-589.92l-315.53,3.831l49.893,383.064
		C1073.358,361.655,1364.616,563.722,1361.92,564.68"
        />
        <path
          id="layer11"
          className="background-path"
          fill="#dcdcdc"
          d="M1093.993-30.889l-449.814,8.33c0,0,0,827.437,4.265,827.437c0.1,0,0.107,0.182,703.776-30.543
		c15.545-146.311-59.325-430.909-136.055-512.289C1170.351,213.455,1077.403,116.846,1093.993-30.889"
        />
        <path
          id="layer10"
          className="background-path"
          fill="#e3e3e3"
          d="M1021.801-29.5l-516.454,6.942c0,0,0,827.437,4.265,827.437c4.264,0,703.776-30.543,703.776-30.543
		c15.546-146.311-191.259-415.012-136.055-512.289C1130.089,169.083,1005.211,118.234,1021.801-29.5"
        />
        <path
          id="layer9"
          className="background-path"
          fill="#c9c9c9"
          d="M982.928-35.054L435.931-22.559c0,0,0,827.437,4.265,827.437s641.302-30.543,641.302-30.543
		c15.546-146.311-23.505-302.653-73.581-402.666C943.814,243.641,966.338,112.681,982.928-35.054"
        />
        <path
          id="layer8"
          className="background-path"
          fill="#dcdcdc"
          d="M948.22-35.054L280.44-22.559c0,0,0,827.437,4.265,827.437s530.237-30.543,530.237-30.543
		c15.546-146.311,152.715-302.653,102.639-402.666C853.479,243.641,931.63,112.681,948.22-35.054"
        />
        <path
          id="layer7"
          className="background-path"
          fill="#e3e3e3"
          d="M899.629-35.054L155.491-22.559c0,0,0,827.437,4.265,827.437c4.264,0,571.886-30.543,571.886-30.543
		c15.546-146.311,67.458-290.368,60.989-402.666C784.398,228.727,883.039,112.681,899.629-35.054"
        />
        <path
          id="layer6"
          className="background-path"
          fill="#dcdcdc"
          d="M862.145-35.054L118.007-22.559c0,0,0,827.437,4.265,827.437c4.264,0,592.71-30.543,592.71-30.543
		c15.546-146.311,46.634-290.368,40.165-402.666C746.914,228.727,845.555,112.681,862.145-35.054"
        />
        <path
          id="layer5"
          className="background-path"
          fill="#c9c9c9"
          d="M806.611-35.054L20.825-22.559c0,0,0,827.437,4.265,827.437c4.264,0,460.259-30.543,460.259-30.543
		c15.547-146.311,165.202-290.368,158.734-402.666C635.849,228.727,790.021,112.681,806.611-35.054"
        />
        <path
          id="layer4"
          className="background-path"
          fill="#a6a6a6"
          d="M674.722-35.054L20.825-22.559c0,0,0,827.437,4.265,827.437c4.264,0,328.369-16.66,328.369-16.66
		c10.486-98.678,63.843-184.033,90.626-280.526C484.522,362.005,652.792,160.222,674.722-35.054"
        />
        <path
          id="layer3"
          className="background-path"
          fill="#c9c9c9"
          d="M554.758-53.622L-84.294-15.908l58.465,823.053l152.195-16.788
		C70.406,626.068,376.797,417.219,417.784,222.394C442.771,103.616,565.572,102.865,554.758-53.622"
        />
        <path
          id="layer2"
          className="background-path"
          fill="#dcdcdc"
          d="M304.216-29L-42.156-3.586l38.041,722.396C15.399,236.004,298.851,311.573,304.216-29"
        />
        <path
          id="layer1"
          className="background-path"
          fill="#dcdcdc"
          d="M1114.527-8.7c38.112,151.395,112.459,223.485,222.855,241.591c0,0,6.282-248.778-2.956-247.027
		C1325.188-12.384,1114.527-8.7,1114.527-8.7"
        />

        <g>
          <g className="color-soft-light">
            <path
              fill="#E3E3E3"
              d="M-354,113.5c15.7-5.6,33,849.8,33,849.8l454.6-5.5L61.7,406C61.7,406-357.9,114.9-354,113.5z"
            />
            <path
              fill="#DCDCDC"
              d="M32,971.5l648-12c0,0,0-1192-6.1-1192c-0.1,0-0.2-0.3-1013.9,44c-22.4,210.8,85.5,620.8,196,738
			C-78,619.5,55.9,758.7,32,971.5z"
            />
            <path
              fill="#E3E3E3"
              d="M136,969.5l744-10c0,0,0-1192-6.1-1192c-6.1,0-1013.9,44-1013.9,44c-22.4,210.8,275.5,597.9,196,738
			C-20,683.4,159.9,756.7,136,969.5z"
            />
            <path
              fill="#C9C9C9"
              d="M192,977.5l788-18c0,0,0-1192-6.1-1192c-6.1,0-923.9,44-923.9,44c-22.4,210.8,33.9,436,106,580.1
			C248.3,576,215.9,764.7,192,977.5z"
            />
            <path
              fill="#DCDCDC"
              d="M242,977.5l962-18c0,0,0-1192-6.1-1192c-6.1,0-763.9,44-763.9,44c-22.4,210.8-220,436-147.9,580.1
			C378.5,576,265.9,764.7,242,977.5z"
            />
            <path
              fill="#E3E3E3"
              d="M312,977.5l1072-18c0,0,0-1192-6.1-1192c-6.1,0-823.9,44-823.9,44c-22.4,210.8-97.2,418.3-87.9,580.1
			C478,597.5,335.9,764.7,312,977.5z"
            />
            <path
              fill="none"
              d="M446,977.5l1132-18c0,0,0-1192-6.1-1192c-6.1,0-663,44-663,44c-22.4,210.8-238,418.3-228.7,580.1
			C692,597.5,469.9,764.7,446,977.5z"
            />
            <path
              fill="#DCDCDC"
              d="M366,977.5l1072-18c0,0,0-1192-6.1-1192c-6.1,0-853.9,44-853.9,44c-22.4,210.8-67.2,418.3-57.9,580.1
			C532,597.5,389.9,764.7,366,977.5z"
            />
            <path
              fill="#C9C9C9"
              d="M446,977.5l1132-18c0,0,0-1192-6.1-1192c-6.1,0-663,44-663,44c-22.4,210.8-238,418.3-228.7,580.1
			C692,597.5,469.9,764.7,446,977.5z"
            />
            <path
              fill="#A6A6A6"
              d="M636,977.5l942-18c0,0,0-1192-6.1-1192c-6.1,0-473,24-473,24c-15.1,142.2-92,265.1-130.6,404.1
			C910,405.5,667.6,696.2,636,977.5z"
            />
            <path
              fill="#C9C9C9"
              d="M808.8,1004.2l920.6-54.3l-84.2-1185.7L1426-211.6c80.6,236.7-360.8,537.5-419.8,818.2
			C970.1,777.7,793.2,778.8,808.8,1004.2z"
            />
            <path
              fill="#DCDCDC"
              d="M1169.7,968.8l499-36.6l-54.8-1040.7C1585.8,587,1177.5,478.2,1169.7,968.8z"
            />
            <path
              fill="#DCDCDC"
              d="M2.4,939.5c-54.9-218.1-162-322-321-348c0,0-9.1,358.4,4.3,355.9C-301.1,944.8,2.4,939.5,2.4,939.5z"
            />
          </g>
        </g>

        <rect
          id="SVGID_7_"
          className="color-multiply"
          x="0"
          y="0"
          fill="url(#linear)"
          width="100%"
          height="100%"
        />
      </svg>
    );
  }
}

export default Background;

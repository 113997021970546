import React, { Component } from 'react';
import classnames from 'classnames';
import { Helmet } from 'react-helmet';
import API from '../services/api';
import { TweenLite } from 'gsap/all';
import { config, ReactGA } from '../config';

import shuffle from '../helpers/shuffle';
import PageInfo from '../components/pageInfo';
import Loader from '../components/loader';
import ImagePod from '../components/imagePod';
import socialImage from '../images/social/about.jpg';

let imageInterval = null;

export default class About extends Component {
  state = {
    loaded: false,
    data: {
      acf: {
        about: []
      }
    },
    imagePod: 1,
    imageIndex: 0,
    images: [],
    work: []
  };

  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
    API.retrieveContent({ prefix: 'page', path: 'about' }).then(res => {
      res.data.acf.about = shuffle(res.data.acf.about);
      this.setState({ data: res.data }, () => {
        this.setImages();
      });
      setTimeout(() => {
        this.setState({ loaded: true });
      }, 10);

      imageInterval = setInterval(this.setImages, 5000);
    });
  }

  componentWillUnmount() {
    clearInterval(imageInterval);
  }

  setImages = () => {
    let images;
    let imageIndex = this.state.imageIndex;
    let imagePod = this.state.imagePod;

    if (this.state.images.length === 0) {
      images = this.state.data.acf.about.slice(0, 2);
      imageIndex = 2;
    } else {
      images = [...this.state.images];
      imageIndex++;
      if (imageIndex > this.state.data.acf.about.length - 1) {
        imageIndex = 0;
      }
      if (imagePod === 0) {
        imagePod = 1;
      } else {
        imagePod = 0;
      }
      images[imagePod] = this.state.data.acf.about[imageIndex];
    }
    this.setState({ images, imageIndex, imagePod });
  };

  imageLoaded() {
    setTimeout(() => {
      TweenLite.fromTo(
        '.about-image img',
        0.5,
        { opacity: 0, y: 20 },
        { opacity: 1, y: 0 }
      );
    }, 1000);
  }

  render() {
    const classes = classnames(
      { loaded: this.state.loaded },
      'page-about',
      'page'
    );

    const imageClasses = classnames(
      { 'image-loaded': this.state.imageLoaded },
      'about-image'
    );

    return (
      <div className={classes}>
        <Helmet>
          <title>{config.TITLE} About</title>
          <meta name="description" content={this.state.data.post_excerpt} />
          <meta property="og:title" content={this.state.data.post_title} />
          <meta property="og:url" content={window.location} />
          <meta property="og:image" content={socialImage} />
        </Helmet>
        <Loader loaded={this.state.loaded} />
        <div className="about-layout">
          <div className="about-content">
            <PageInfo subtitle="something" delta={0} data={this.state.data} />
          </div>
          <div className={imageClasses}>
            {this.state.images.map((image, index) => {
              const direction = index === 0 ? 'top' : 'right';
              return (
                <ImagePod
                  key={index}
                  direction={direction}
                  hex="#0AB4C4"
                  img={image.about_image.sizes.large}
                  index={index}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

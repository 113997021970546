import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const propTypes = {
  active: PropTypes.bool.isRequired,
  callback: PropTypes.func.isRequired,
  data: PropTypes.shape({
    title: PropTypes.string.isRequired
  })
}

function ProcessItem(props) {
  const { title } = props.data;

  const processListClasses = classnames('process-list-item', {
    active: props.active
  });

  const titleSplit = title.split(' + ');

  return (
    <div className={processListClasses} onClick={props.callback}>
      <div className="process-list-item__content">
        <div className="process-list-item__pod">
          {titleSplit[0].substr(0, 2)}
        </div>
        +
        <div className="process-list-item__pod">
          {titleSplit[1].substr(0, 2)}
        </div>
      </div>
    </div>
  );
}

ProcessItem.propTypes = propTypes;
export default ProcessItem
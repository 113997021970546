import React from 'react';

export default function BottomBump() {
  return (
    <svg
      id="bottomBump"
      x="0px"
      y="0px"
      width="100%"
      height="100%"
      preserveAspectRatio="none"
      viewBox="0 0 1024 200"
    >
      <g id="Scene_1_l1" overflow="visible">
        <g style={{ display: 'none' }}>
          <path
            fill="#90c8ad"
            d="M1024,201.05L1024,46.05Q488.491,-44.213 0,46.05L0,201.05 1024,201.05"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.917s"
            keyTimes="0;1;1"
            values="none;inline;inline"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill="#90c8ad"
            d="M1024,201.05L1024,44.3Q488,-42.45 0,44.3L0,201.05 1024,201.05"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.917s"
            keyTimes="0;0.955;1"
            values="none;inline;inline"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill="#90c8ad"
            d="M1024,201.05L1024,39.05Q486.5,-37.25 0,39.05L0,201.05 1024,201.05"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.917s"
            keyTimes="0;0.909;0.955;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill="#90c8ad"
            d="M1024,201.05L1024,30.3Q484,-28.6 0,30.3L0,201.05 1024,201.05"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.917s"
            keyTimes="0;0.864;0.909;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill="#90c8ad"
            d="M1024,201.05L1024,18.05Q480.496,-16.486 0,18.05L0,201.05 1024,201.05"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.917s"
            keyTimes="0;0.818;0.864;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill="#90c8ad"
            d="M1024,201.05L1024,23.15Q480.5,-21.6 0,23.15L0,201.05 1024,201.05"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.917s"
            keyTimes="0;0.773;0.818;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill="#90c8ad"
            d="M1024,201.05L1024,38.55Q480.45,-37 0,38.55L0,201.05 1024,201.05"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.917s"
            keyTimes="0;0.727;0.773;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill="#90c8ad"
            d="M1024,201.05L1024,64.15Q480.4,-62.6 0,64.15L0,201.05 1024,201.05"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.917s"
            keyTimes="0;0.682;0.727;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill="#90c8ad"
            d="M1024,201.05L1024,99.95Q480.3,-98.45 0,99.95L0,201.05 1024,201.05"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.917s"
            keyTimes="0;0.636;0.682;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill="#90c8ad"
            d="M1024,201.05L1024,146.05Q480.2,-144.55 0,146.05L0,201.05 1024,201.05"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.917s"
            keyTimes="0;0.591;0.636;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill="#90c8ad"
            d="M1024,201.35L1024,150.35Q482.65,-117.9 0,150.35L0,201.35 1024,201.35"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.917s"
            keyTimes="0;0.545;0.591;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill="#90c8ad"
            d="M1024,201.65L1024,154.65Q485.1,-91.25 0,154.65L0,201.65 1024,201.65"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.917s"
            keyTimes="0;0.5;0.545;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill="#90c8ad"
            d="M1024,201.95L1024,158.95Q487.55,-64.55 0,158.95L0,201.95 1024,201.95"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.917s"
            keyTimes="0;0.455;0.5;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill="#90c8ad"
            d="M1024,202.3L1024,163.3Q490,-37.9 0,163.3L0,202.3 1024,202.3"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.917s"
            keyTimes="0;0.409;0.455;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill="#90c8ad"
            d="M1024,202.6L1024,167.6Q492.45,-11.25 0,167.6L0,202.6 1024,202.6"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.917s"
            keyTimes="0;0.364;0.409;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill="#90c8ad"
            d="M1024,202.9L1024,171.9Q494.9,15.4 0,171.9L0,202.9 1024,202.9"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.917s"
            keyTimes="0;0.318;0.364;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill="#90c8ad"
            d="M1024,203.2L1024,176.2Q497.3,42.1 0,176.2L0,203.2 1024,203.2"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.917s"
            keyTimes="0;0.273;0.318;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill="#90c8ad"
            d="M1024,203.5L1024,180.5Q499.75,68.75 0,180.5L0,203.5 1024,203.5"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.917s"
            keyTimes="0;0.227;0.273;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill="#90c8ad"
            d="M1024,203.8L1024,184.8Q502.2,95.4 0,184.8L0,203.8 1024,203.8"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.917s"
            keyTimes="0;0.182;0.227;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill="#90c8ad"
            d="M1024,204.15L1024,189.15Q504.65,122.05 0,189.15L0,204.15 1024,204.15"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.917s"
            keyTimes="0;0.136;0.182;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill="#90c8ad"
            d="M1024,204.45L1024,193.45Q507.1,148.75 0,193.45L0,204.45 1024,204.45"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.917s"
            keyTimes="0;0.091;0.136;1"
            values="none;inline;none;none"
          />
        </g>
        <g style={{ display: 'none' }}>
          <path
            fill="#90c8ad"
            d="M1024,204.75L1024,197.75Q509.55,175.4 0,197.75L0,204.75 1024,204.75"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.917s"
            keyTimes="0;0.045;0.091;1"
            values="none;inline;none;none"
          />
        </g>
        <g>
          <path
            fill="#90c8ad"
            d="M1024,205.05L1024,202.05 0,202.05 0,205.05 1024,205.05"
          />
          <animate
            attributeName="display"
            fill="freeze"
            repeatCount="1"
            dur="0.917s"
            keyTimes="0;0.045;1"
            values="inline;none;none"
          />
        </g>
      </g>
    </svg>
  );
}

import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const propTypes = {
  data: PropTypes.shape({
    post_title: PropTypes.string,
    post_content: PropTypes.string
  }),
  delta: PropTypes.number
};

const defaultPropTypes = {
  data: {
    post_title: '',
    post_content: ''
  }
};

class WordsPageInfo extends Component {
  render() {
    const { post_title, post_content } = this.props.data;
    const classes = classnames('words-page-info', {
      compact: this.props.delta
    });
    return (
      <div className={classes}>
        <div className="words-page-title__container">
          <div className="words-page-title__mask">
            <h1 className="words-page-title words-page-title--top">
              {post_title}
            </h1>
          </div>
          <div className="words-page-title__mask words-page-title__mask--bottom">
            <h1 className="words-page-title words-page-title--bottom">
              {post_title}
            </h1>
          </div>
        </div>
        <h2
          className="words-page-description"
          dangerouslySetInnerHTML={{ __html: post_content }}
        />
      </div>
    );
  }
}

WordsPageInfo.propTypes = propTypes;
WordsPageInfo.defaultPropTypes = defaultPropTypes;
export default WordsPageInfo;
import React, { Component } from 'react';
import API from '../services/api';
import { Helmet } from 'react-helmet';
import { config, ReactGA } from '../config';
import classnames from 'classnames';
import { TweenMax } from 'gsap/all';
import Lottie from 'react-lottie';
import $ from 'jquery';

import Background from '../backgrounds/home';
import * as HomeTransition from '../transitions/lottie/pink.json';
import Loader from '../components/loader';
import Logo from '../components/logo';
import socialImage from '../images/social/home.jpg';

export default class Home extends Component {
  state = {
    data: {
      acf: {
        subtitle_1: '',
        subtitle_2: ''
      }
    },
    animationReady: false,
    navReady: false
  };

  componentDidMount() {
    $('.hamburger').removeClass('load-anim');
    ReactGA.pageview(window.location.pathname);
    API.retrieveContent({ prefix: 'page', path: 'home' })
      .then(res => {
        this.setState({ data: res.data, loaded: true });
        setTimeout(() => {
          this.setState({ animationReady: true });
        }, 500);

        setTimeout(() => {
          $('.hamburger').addClass('load-anim');
        }, 1500);

        setTimeout(() => {
          TweenMax.set('.home-tagline__content', {
            maxHeight: 400,
            paddingTop: 20,
            paddingBottom: 20
          });

          TweenMax.delayedCall(0.5, () => {
            TweenMax.staggerFromTo(
              '.home-tagline__line',
              0.5,
              {
                opacity: 0,
                y: 30
              },
              {
                opacity: 1,
                y: 0
              },
              0.1
            );
          });
        }, 2000);
      })
      .catch(res => {
        console.log(res);
      });
  }

  render() {
    const classes = classnames(
      { loaded: this.state.loaded },
      'page-home',
      'page'
    );

    const animationOptions = {
      loop: false,
      autoplay: true,
      animationData: HomeTransition.default,
      rendererSettings: {
        className: ' transition',
        preserveAspectRatio: 'none'
      },
      height: '100%',
      width: '100%'
    };

    return (
      <div className={classes}>
        <Helmet>
          <title>{config.TITLE} Home</title>
          <meta
            name="description"
            content="We help brands build confidence and connection."
          />
          <meta property="og:title" content="Greater Than" />
          <meta property="og:url" content={window.location} />
          <meta property="og:image" content={socialImage} />
        </Helmet>
        <Loader loaded={this.state.loaded} />
        <div className="home-intro">
          {this.state.animationReady && (
            <div className="intro-logo">
              <Logo className="home-logo" />
            </div>
          )}
          <div className="home-tagline">
            <div className="home-tagline__content">
              <div className="home-tagline__line">
                {this.state.data.acf.subtitle_1}{' '}
              </div>
              <div className="home-tagline__line">
                {this.state.data.acf.subtitle_2}
              </div>
            </div>
          </div>
        </div>
        {this.state.animationReady && <Background />}
        {this.state.loaded && (
          <Lottie className="transition" options={animationOptions} />
        )}
      </div>
    );
  }
}

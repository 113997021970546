import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  data: PropTypes.shape({
    client_name: PropTypes.string,
    project_name: PropTypes.string,
    project_description: PropTypes.string,
    categories: PropTypes.any
  })
};

function WorkContent(props) {
  const {
    client_name,
    project_name,
    project_description,
    categories
  } = props.data;

  return (
    <Fragment>
      <div className="work-detail__client">{client_name}</div>
      <div className="work-detail__name">{project_name}</div>
      <div className="work-detail__description">{project_description}</div>
      {typeof categories === 'object' && (
        <div className="work-detail__services">
          {categories.map((category, index) => {
            return (
              category.charAt(0).toUpperCase() +
              category.slice(1) +
              (index < categories.length - 1 ? ' • ' : '')
            );
          })}
        </div>
      )}
    </Fragment>
  );
}

WorkContent.propTypes = propTypes;
export default WorkContent;

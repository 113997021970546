import { Component } from 'react';
import $ from 'jquery';
import { throttle } from 'underscore';

export default class Scroll extends Component {
  constructor(props) {
    super(props);
    this.delta = this.props.currentIndex || null;
    this.target = this.props.target || '.page';
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentIndex !== this.props.currentIndex) {
      this.delta = this.props.currentIndex;
    }
  }

  componentWillUnmount() {
    $(this.target).off('mousewheel DOMMouseScroll keydown touchmove');
  }

  componentDidMount() {
    $(this.target).on(
      'mousewheel DOMMouseScroll keydown touchmove',
      throttle(e => {
        if ($(window).outerWidth() >= 700) {
          let delta = null;
          // Mobile
          if (e.originalEvent.touches && e.originalEvent.touches[0].clientY) {
            const diff = e.originalEvent.touches[0].clientY - this.lastY;
            if (diff < 0) {
              delta = this.delta + 1;
            } else {
              delta = this.delta - 1;
            }
            this.lastY = e.originalEvent.touches[0].clientY;
          }

          // prevent non nav keys from registering
          if (e.keyCode) {
            delta = this.delta;
          }

          // Down
          if (e.keyCode === 40 || e.keyCode === 39) {
            delta = this.delta + 1;
          }

          // UP
          if (e.keyCode === 38 || e.keyCode === 37) {
            delta = this.delta - 1;
          }

          // ff
          if (e.detail) {
            // e.preventDefault();
            const ac = this.props.scrollTarget
              ? $(this.props.scrollTarget).scrollTop()
              : 0;

            // up
            if (e.detail < 0 && ac === 0) {
              delta = this.delta - 1;
            } else {
              delta = this.delta + 1;
            }
          } else {
            // chrome
            if (e.originalEvent.deltaY !== undefined) {
              // e.preventDefault();
              const ac = this.props.scrollTarget
                ? $(this.props.scrollTarget).scrollTop()
                : 0;
              // up
              if (e.originalEvent.deltaY < 0 && ac === 0) {
                delta = this.delta - 1;
              } else {
                delta = this.delta + 1;
              }
            }
          }

          if (delta > this.props.maxSlides) {
            delta = this.props.maxSlides;
          }

          if (delta < 0) {
            delta = 0;
          }

          if (this.delta !== delta && delta != null) {
            this.delta = delta;
            setTimeout(() => {
              this.props.callback(delta);
            }, 200);
          }
        }
      }, 1000)
    );
  }

  render() {
    return null;
  }
}

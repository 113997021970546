import React from 'react';

function BackButton(props) {
  return (
    <svg
      x="0px"
      y="0px"
      width="20.123px"
      height="21.03px"
      viewBox="0 0 20.123 21.03"
    >
      <path
        fill={props.hex}
        d="M19.123,9.516H3.114l7.978-7.978c0.352-0.352,0.352-0.922,0-1.273c-0.352-0.353-0.922-0.353-1.273,0
	L0.256,9.826c-0.189,0.189-0.269,0.44-0.254,0.687c-0.015,0.249,0.063,0.502,0.253,0.692l9.562,9.562
	c0.176,0.176,0.406,0.264,0.637,0.264s0.461-0.088,0.637-0.264c0.352-0.352,0.352-0.922,0-1.273l-7.978-7.978h16.01
	c0.553,0,1-0.448,1-1S19.675,9.516,19.123,9.516z"
      />
    </svg>
  );
}

BackButton.defaultProps = {
  hex: '#00CE9B'
};

export default BackButton;

import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const propTypes = {
  active: PropTypes.bool.isRequired,
  callback: PropTypes.func.isRequired,
  data: PropTypes.shape({
    acf: PropTypes.shape({
      excerpt: PropTypes.string,
      image: PropTypes.shape({
        sizes: PropTypes.shape({
          thumbnail: PropTypes.string.isRequired
        })
      }),
      read_time: PropTypes.string
    }),
    post_title: PropTypes.string
  })
}

function WordItem(props) {
  const { post_title } = props.data;

  const wordListClasses = classnames('word-list-item', {
    active: props.active
  });
  return (
    <div className={wordListClasses} onClick={props.callback}>
      <div className="word-list-item__image">
        <img src={props.data.acf.image.sizes.thumbnail} alt={post_title} />
      </div>
      <div className="word-list-item__content">
        <div className="word-list-item__title">{post_title}</div>
        <div
          className="word-list-item__description"
          dangerouslySetInnerHTML={{ __html: props.data.acf.excerpt }}
        />
        <div className="word-list-item__meta">
          <div className="word-list-item__time">
            {props.data.acf.read_time} read
          </div>
          <div className="word-list-item__link">More ></div>
        </div>
      </div>
      <div className="word-list-item__background" />
    </div>
  );
}

WordItem.propTypes = propTypes;
export default WordItem;
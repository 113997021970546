import React, { Component, Fragment } from 'react';
import classnames from 'classnames';
import Slider from 'react-slick';
import PropTypes from 'prop-types';

const propTypes = {
  callback: PropTypes.func,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.string,
      description: PropTypes.shape
    })
  ).isRequired
};

class ContentSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slideIndex: 0,
      updateCount: 0,
      transitioning: false
    };
    this.slider = React.createRef();
  }

  changeSlide = index => {
    this.setState({ transitioning: true });
    setTimeout(() => {
      this.slider.slickGoTo(index, true);
      this.setState({ transitioning: false });
    }, 1000);

    if (this.props.callback) {
      this.props.callback(index);
    }
  };

  render() {
    const settings = {
      accessibility: false,
      arrows: false,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipe: false,
      afterChange: () =>
        this.setState(state => ({ updateCount: state.updateCount + 1 })),
      beforeChange: (current, next) => this.setState({ slideIndex: next })
    };

    return (
      <Fragment>
        <Slider
          ref={slider => (this.slider = slider)}
          className="content-slider"
          {...settings}
        >
          {this.props.data &&
            this.props.data.map((item, index) => {
              const slideClass = classnames('content-slide-item', {
                active:
                  index === this.state.slideIndex && !this.state.transitioning
              });

              return (
                <div className={slideClass} key={item.title}>
                  <div className="content-slide-item__title">{item.title}</div>
                  <div className="content-slide-item__description">
                    {item.description}
                  </div>
                </div>
              );
            })}
        </Slider>
        <div className="custom-dots">
          {this.props.data &&
            this.props.data.map((item, index) => {
              const dotClasses = classnames('dot', {
                active: index === this.state.slideIndex
              });
              return (
                <div
                  key={item.title}
                  onClick={e => this.changeSlide(index)}
                  className={dotClasses}
                >
                  <div className="dot-title">{item.title}</div>
                </div>
              );
            })}
        </div>
      </Fragment>
    );
  }
}

ContentSlider.propTypes = propTypes;
export default ContentSlider;

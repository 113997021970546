import React, { Component } from 'react';
import { TweenMax, Power2, TimelineMax, Back, Power0 } from 'gsap/all';
import PropTypes from 'prop-types';
import $ from 'jquery';

const propTypes = {
  loaded: PropTypes.bool
};

let tl = new TimelineMax();

class Loader extends Component {
  componentDidMount() {
    this.animateIn();
  }

  componentDidUpdate(prevProps) {
    if (this.props.loaded !== prevProps.loaded) {
      if (!this.props.loaded) {
        this.animateIn();
      } else {
        this.animateOut();
      }
    }
  }

  animateOut() {
    $('.main-content').animate({ scrollTop: 0 }, 1);

    tl.clear();

    TweenMax.to('#loader-underline', 0.5, {
      width: '100%',
      ease: Back.easeOut
    });

    TweenMax.to('#loader-dot', 0.5, { top: '100%', ease: Back.easeIn });

    TweenMax.to('#loader-underline', 0.5, {
      width: '0%',
      delay: 0.1,
      ease: Back.easeIn
    });

    TweenMax.to('#loader-orbit', 0.5, {
      top: '100%',
      opacity: 0,
      delay: 0.1,
      ease: Back.easeIn
    });
  }

  animateIn() {
    TweenMax.to('#loader-dot', 0.2, {
      top: 30,
      scale: 1,
      ease: Power2.easeOut
    });
    TweenMax.to('.angle', 0.3, {
      width: '50%',
      ease: Power2.easeOut,
      delay: 0.1
    });

    TweenMax.to('#angle-up', 0.3, {
      rotation: 30,
      transformOrigin: 'right bottom',
      ease: Power2.easeOut,
      delay: 0.3
    });

    TweenMax.to('#angle-down', 0.3, {
      rotation: -30,
      transformOrigin: 'right top',
      ease: Power2.easeOut,
      delay: 0.3
    });

    TweenMax.set('#loader-orbit', { rotation: 0 });

    TweenMax.to('#loader-orbit', 0.3, {
      top: 5,
      opacity: 1,
      transformOrigin: 'center bottom',
      // ease: Power2.easeOut,
      delay: 0.3
    });

    tl.to('#loader-orbit', 1, {
      rotation: 360,
      repeat: -1,
      transformOrigin: 'center bottom',
      ease: Power0.easeNone,
      delay: 0.3
    });
  }

  render() {
    return (
      <div id="loader">
        <div id="loader-dot">
          <div id="angle-up" className="angle" />
          <div id="angle-down" className="angle" />
        </div>
        <div id="loader-orbit" />
        <div id="loader-underline" />
      </div>
    );
  }
}

Loader.propTypes = propTypes;
export default Loader;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import BottomBump from '../components/bottomBump';
import SocialLink from '../components/socialLink';

const propTypes = {
  hex: PropTypes.string,
  info: PropTypes.shape({
    acf: PropTypes.shape({
      address: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      tagline: PropTypes.string.isRequired
    }),
    socialIcons: PropTypes.arrayOf({
      icon: PropTypes.shape({
        sizes: PropTypes.shape({
          thumbnail: PropTypes.string.isRequired
        })
      }),
      link: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  })
};

const defaultProps = {
  hex: '#f2adb2'
};

class ContactInfo extends Component {
  state = {
    loaded: false,
    data: {}
  };

  render() {
    if (!this.props.info) {
      return null;
    }

    const emailLink = `mailto:${this.props.info.acf.email}`;

    return (
      <div className="contact-info">
        <BottomBump />
        <div className="contact-info__container">
          <div className="contact-info__column">
            <div className="contact-info__label">contact</div>
            <div className="contact-info__divider" />

            <div className="contact-info__content">
              <div
                dangerouslySetInnerHTML={{
                  __html: this.props.info.acf.address
                }}
              />
              <div className="contact-info__tagline">
                {this.props.info.acf.tagline}
              </div>
            </div>
          </div>
          <div className="contact-info__column contact-info__column--social">
            <div className="contact-info__talk">
              <div>Let's talk</div>
              {this.props.info.acf && (
                <a className="email-link" href={emailLink}>
                  {this.props.info.acf.email}
                </a>
              )}
            </div>
            <div className="contact-info__social">
              {this.props.info.acf.social_icons.map(social => {
                return (
                  <SocialLink
                    key={social.icon.name}
                    name={social.icon.name}
                    link={social.link}
                    icon={social.icon.sizes.thumbnail}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ContactInfo.defaultProps = defaultProps;
ContactInfo.propTypes = propTypes;

export default ContactInfo;

import React, { Component } from 'react';
import classnames from 'classnames';
import { Helmet } from 'react-helmet';
import { config, ReactGA } from '../config';
import { TweenMax, Power2 } from 'gsap/all';

import API from '../services/api';
import History from '../helpers/history';
import WorkSlider from '../components/workSlider';
import WorkContent from '../components/workContent';
import Loader from '../components/loader';
import WorkSideBlock from '../components/workSideBlock';

export default class WorkDetail extends Component {
  state = {
    loaded: false,
    data: {
      acf: {
        work_image: [],
        categories: []
      }
    },
    initialLoad: false,
    work: [],
    workItems: [],
    videoStatus: null
  };

  componentDidUpdate(prevProps) {
    if (this.props.match.params.slug !== prevProps.match.params.slug) {
      this.loadAllContent();
    }
  }

  componentDidMount() {
    this.loadAllContent();
  }

  loadAllContent() {
    ReactGA.pageview(window.location.pathname);

    // load work content
    API.retrieveContent({
      prefix: 'work',
      path: this.props.match.params.slug
    }).then(res => {
      this.setState({ data: res.data, initialLoad: true }, () => {
        TweenMax.fromTo(
          '.work-detail__content-column',
          0.5,
          {
            opacity: 0,
            y: 30
          },
          {
            opacity: 1,
            y: 0,
            ease: Power2.easeOut
          }
        );

        TweenMax.fromTo(
          '.work-slider',
          0.5,
          {
            opacity: 0,
            y: 30
          },
          {
            opacity: 1,
            y: 0,
            ease: Power2.easeOut
          }
        ).delay(0.5);

        TweenMax.to('.work-slider__placeholder', 0.5, {
          opacity: 1,
          y: 0,
          ease: Power2.easeOut
        }).delay(0.8);

        TweenMax.to('.custom-dots__placeholder', 0.5, {
          opacity: 1,
          y: 0,
          ease: Power2.easeOut
        }).delay(1);
      });
    });

    //load all work items
    API.retrieveContent({ prefix: '', path: 'work' }).then(res => {
      this.setState({ workItems: res.data, loaded: true });
      TweenMax.staggerTo(
        '.work-detail__thumb',
        0.4,

        {
          opacity: 1,
          y: 1
        },
        0.1
      );

      TweenMax.fromTo(
        '.custom-dots',
        0.5,
        {
          opacity: 0,
          y: 30
        },
        {
          opacity: 1,
          y: 0,
          ease: Power2.easeOut
        }
      );
    });
  }

  navigate = (e, page) => {
    const oldPath = window.location.pathname.split('/').pop();

    if (page !== oldPath) {
      this.setState({ loaded: false });

      TweenMax.to('.work-detail__content-column', 0.5, {
        opacity: 0,
        y: 30,
        ease: Power2.easeIn
      });

      TweenMax.to('.work-slider', 0.5, {
        opacity: 0,
        y: 30,
        ease: Power2.easeIn
      }).delay(0.2);

      TweenMax.to('.work-slider__placeholder', 0.5, {
        opacity: 0,
        y: 30,
        ease: Power2.easeIn,
        onComplete: () => {
          this.props.updateVideoStatus(null);
          History.push(page);
        }
      }).delay(0.4);

      TweenMax.to('.custom-dots__placeholder', 0.5, {
        opacity: 0,
        y: 30,
        ease: Power2.easeInOut
      }).delay(0.6);
    }
  };

  videoPlayCallback = event => {
    this.props.updateVideoStatus('playing');
  };

  videoStopCallback = event => {
    this.props.updateVideoStatus(null);
  };

  render() {
    const classes = classnames(
      { loaded: this.state.loaded },
      'page-practice__detail',
      'page'
    );

    const workContent = {
      client_name: this.state.data.acf.client_name,
      project_name: this.state.data.acf.project_name,
      project_description: this.state.data.acf.project_description,
      categories: this.state.data.acf.categories
    };

    return (
      <div className={classes}>
        {this.state.data.acf.work_image.length && (
          <Helmet>
            <meta
              name="description"
              content={workContent.project_description}
            />
            <meta property="og:title" content={workContent.project_name} />
            <meta property="og:url" content={window.location} />
            <meta property="og:image" content={this.state.data.image} />
            <title>
              {config.TITLE} {workContent.project_name}
            </title>
          </Helmet>
        )}

        <Loader loaded={this.state.loaded} />
        {/* <Background status={this.state.videoStatus} /> */}

        <div className="work-detail">
          <div className="work-detail__container">
            <div className="work-detail__content work-detail__content--left">
              {this.state.initialLoad && <WorkSideBlock />}

              <div className="work-detail__content-column">
                <WorkContent data={workContent} />
                <div className="work-selector">
                  <div className="custom-dots">
                    {this.state.workItems.map((item, index) => {
                      const dotClasses = classnames('dot', {
                        active: item.post_name === this.props.match.params.slug
                      });
                      return (
                        <div
                          key={index}
                          onClick={e => this.navigate(e, item.post_name)}
                          className={dotClasses}
                        >
                          <div className="dot-title">{item.post_title}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="work-detail__content">
              <div className="work-detail__content-container">
                <WorkSlider
                  data={this.state.data.acf.work_image}
                  key={this.state.data.ID}
                  videoPlayCallback={this.videoPlayCallback}
                  videoStopCallback={this.videoStopCallback}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

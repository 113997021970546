import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  icon: PropTypes.string,
  link: PropTypes.string,
  name: PropTypes.string
}

function SocialIcon({ name, link, icon }) {
  return (
    <a
      className="social-link"
      rel="noopener noreferrer"
      target="_blank"
      href={link}
    >
      <img src={icon} alt={name} />
    </a>
  );
}

SocialIcon.propTypes = propTypes;
export default SocialIcon;

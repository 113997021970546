import React, { Component } from 'react';
import classnames from 'classnames';
import { Helmet } from 'react-helmet';
import { config, ReactGA } from '../config';
import API from '../services/api';
import $ from 'jquery';
import { TweenMax, Power2 } from 'gsap/all';

import WorkPod from '../components/workPod';
import PageInfo from '../components/pageInfo';
import Loader from '../components/loader';
import Scroll from '../helpers/scroll';
import socialImage from '../images/social/work.jpg';

export default class Work extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      data: {
        acf: {
          logo: []
        }
      },
      work: []
    };
    this.delta = null;
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname);

    // load work content
    API.retrieveContent({ prefix: 'page', path: 'practice' }).then(dataRes => {
      // load actual work
      API.retrieveContent({ prefix: '', path: 'work' }).then(res => {
        this.setState({ data: dataRes.data, work: res.data }, () => {
          TweenMax.staggerFromTo(
            '.client-logo',
            1,
            {
              y: 20,
              opacity: 0
            },
            {
              y: 0,
              opacity: 1,
              ease: Power2.easeOut
            },
            0.1
          );
        });
        setTimeout(() => {
          this.setState({ loaded: true });
        }, 10);
      });
    });
  }

  scrollCallback = index => {
    this.delta = index;
    if (index === 1) {
      this.forceUpdate();
      TweenMax.staggerFromTo(
        '.client-logo',
        1,
        {
          y: 0,
          opacity: 1
        },
        {
          y: -30,
          opacity: 0,
          ease: Power2.easeIn
        },
        0.1
      );
    } else {
      setTimeout(() => {
        TweenMax.staggerFromTo(
          '.client-logo',
          1,
          {
            y: -30,
            opacity: 0
          },
          {
            y: 0,
            opacity: 1,
            ease: Power2.easeOut
          },
          0.1
        );
      }, 1000);

      TweenMax.staggerFromTo(
        '.work-item',
        0.2,
        {
          opacity: 1
        },
        {
          opacity: 0,
          autoAlpha: true
        },

        0.05,
        () => {
          this.forceUpdate();
        }
      );
    }
  };

  goToDetail = (event, location) => {
    this.props.navigate(event, `/work/${location}`);
  };

  render() {
    const mobile = $(window).width() < 800;

    const classes = classnames(
      { loaded: this.state.loaded },
      'page-practice',
      'page'
    );

    const workClasses = classnames('work-slides', {
      compact: this.delta || mobile
    });

    const scrollClasses = classnames('icon-scroll', {
      compact: this.delta || mobile
    });

    return (
      <div className={classes}>
        <Helmet>
          <title>{config.TITLE} Work</title>
          <meta name="description" content={this.state.data.post_excerpt} />
          <meta property="og:title" content={this.state.data.post_title} />
          <meta property="og:url" content={window.location} />
          <meta property="og:image" content={socialImage} />
        </Helmet>
        <Scroll
          callback={this.scrollCallback}
          maxSlides={1}
          scrollTarget=".work-slides"
        />

        <Loader loaded={this.state.loaded} />

        <div className="work-intro__container">
          <PageInfo
            delta={this.delta}
            subtitle="our work"
            data={this.state.data}
          />

          <div className="logo-container">
            {this.state.data.acf.logo.map((logo, index) => {
              return (
                <div className="client-logo" key={index}>
                  <img src={logo.image.sizes.feature} alt={logo.image.title} />
                </div>
              );
            })}
          </div>
        </div>

        {this.state.loaded && <div className={scrollClasses} />}

        <div className={workClasses}>
          <div className="work-container">
            {(this.delta === 1 || mobile) &&
              this.state.work.map((workItem, index) => {
                return (
                  <WorkPod
                    index={index}
                    callback={this.goToDetail}
                    name={workItem.post_name}
                    key={workItem.ID}
                    data={workItem.acf}
                  />
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}

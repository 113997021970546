import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TweenMax, Power2 } from 'gsap/all';

const propTypes = {
  data: PropTypes.shape({
    post_title: PropTypes.string,
    post_content: PropTypes.string
  })
};

const defaultPropTypes = {
  data: {
    post_title: '',
    post_content: ''
  }
};

class PageInfo extends Component {
  componentWillUpdate(prevProps) {
    if (prevProps.data.post_content !== this.props.data.post_content) {
      TweenMax.to('.page-description', 1, {
        y: 0,
        opacity: 1,
        ease: Power2.easeOut
      }).delay(0.5);
    }

    if (prevProps.delta !== this.props.delta) {
      if (this.props.delta === 0 || this.props.delta === null) {
        TweenMax.to('.page-title__container', 1, {
          y: -20,
          opacity: 0,
          ease: Power2.easeIn
        });

        TweenMax.to('.page-description', 1, {
          y: -20,
          opacity: 0,
          ease: Power2.easeIn
        }).delay(0.3);
      } else {
        TweenMax.to('.page-title__container', 1, {
          y: 0,
          opacity: 1,
          ease: Power2.easeOut
        }).delay(0.3);

        TweenMax.to('.page-description', 1, {
          y: 0,
          opacity: 1,
          ease: Power2.easeOut
        });
      }
    }
  }

  componentDidMount() {
    // TweenMax.to('.page-description', 1, {
    //   y: 0,
    //   opacity: 1,
    //   ease: Power2.easeOut
    // }).delay(1);
  }

  render() {
    const { post_title, post_content } = this.props.data;

    return (
      <div className="page-info">
        <div className="page-title__container">
          <div className="page-title__mask">
            <h1 className="page-title page-title--top">{post_title}</h1>
          </div>
          <div className="page-title__mask page-title__mask--bottom">
            <h1 className="page-title page-title--bottom">{post_title}</h1>
          </div>
        </div>
        <h2
          className="page-description"
          dangerouslySetInnerHTML={{ __html: post_content }}
        />
      </div>
    );
  }
}

PageInfo.propTypes = propTypes;
PageInfo.defaultPropTypes = defaultPropTypes;
export default PageInfo;

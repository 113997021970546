import axios from 'axios';

const baseURL = `${process.env.REACT_APP_API_URI}/wp-json/greater/v3`;

const API = {
  retrieveContent({ prefix, path }) {
    const formattedPrefix = prefix ? `${prefix}/` : '';
    return axios.get(`${baseURL}/${formattedPrefix}${path}`);
  }
};

export default API;

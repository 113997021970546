import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import classnames from 'classnames';
import { config, ReactGA } from '../config';
import { TweenMax } from 'gsap/all';
import $ from 'jquery';

import API from '../services/api';
import Loader from '../components/loader';
import PageInfo from '../components/pageInfo';
import ContentSlider from '../components/contentSlider';
import Scroll from '../helpers/scroll';
import socialImage from '../images/social/alignment.jpg';
import AlignmentQuote from '../components/alignmentQuote';

export default class Alignment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      slideIndex: 0,
      data: {
        acf: {
          alignment_item: []
        }
      }
    };

    this.delta = 0;
    this.lastY = 0;
  }

  scrollCallback = index => {
    this.delta = index;
    if (index === 1) {
      this.forceUpdate();
      TweenMax.to('.alignment-container', 0.5, {
        opacity: 1,
        y: 0
      }).delay(0.5);
    } else {
      setTimeout(() => {
        this.forceUpdate();
      }, 500);
      TweenMax.to('.alignment-container', 0.5, {
        opacity: 0,
        y: 30
      });
    }
  };

  componentDidMount() {
    ReactGA.pageview(window.location.pathname);

    API.retrieveContent({ prefix: 'page', path: 'battle-tested' }).then(res => {
      this.setState({ data: res.data });
      setTimeout(() => {
        this.setState({ loaded: true });
      }, 10);
    });
  }

  transitionComplete = index => {
    this.setState({ slideIndex: index });
  };

  changeSlide = index => {
    this.transitionComplete(index);
  };

  render() {
    const mobile = $(window).width() < 800;
    const classes = classnames(
      { loaded: this.state.loaded },
      'page-alignment',
      'page'
    );

    const alignmentClasses = classnames('alignment-container', {
      compact: this.delta
    });

    const scrollClasses = classnames('icon-scroll', { compact: this.delta });

    return (
      <div className={classes}>
        <Helmet>
          <title>{config.TITLE} Alignment</title>
          <meta name="description" content={this.state.data.post_excerpt} />
          <meta property="og:title" content={this.state.data.post_title} />
          <meta property="og:url" content={window.location} />
          <meta property="og:image" content={socialImage} />
        </Helmet>
        <Scroll
          callback={this.scrollCallback}
          maxSlides={1}
          scrollTarget=".alignment-container"
        />
        <Loader loaded={this.state.loaded} />
        <PageInfo
          delta={this.delta}
          subtitle="our work"
          data={this.state.data}
        />
        {this.state.loaded && <div className={scrollClasses} />}
        <div className={alignmentClasses}>
          <div className="alignment__content-column alignment__content-column--left">
            <div className="alignment__content-container">
              {this.state.loaded && (
                <ContentSlider
                  callback={this.changeSlide}
                  data={this.state.data.acf.alignment_item}
                />
              )}
            </div>
          </div>
          <div className="alignment__content-column">
            <div className="alignment__video-container">
              {this.state.loaded &&
                (this.delta === 1 || mobile) &&
                this.state.data.acf.alignment_item.map((item, index) => {
                  const classname = classnames({
                    active: index === this.state.slideIndex
                  });
                  return (
                    <AlignmentQuote
                      attribution={item.quote_attribution}
                      className={classname}
                      key={index}
                      index={index}
                      quote={item.quote}
                      image={item.image.sizes['post-thumbnail']}
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

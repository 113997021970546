import React from 'react';
import { IoIosArrowDropleft } from 'react-icons/io';
import { IoIosArrowDropright } from 'react-icons/io';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Loader from '../components/loadingAnim';

const propTypes = {
  backCallback: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
  curSlide: PropTypes.number.isRequired,
  emailPlaceholder: PropTypes.string,
  messagePlaceholder: PropTypes.string,
  namePlaceholder: PropTypes.string,
  response: PropTypes.string,
  slideNumber: PropTypes.number.isRequired,
  submitting: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  valid: PropTypes.bool.isRequired,
  validate: PropTypes.func.isRequired
};

function ContactSlide(props) {
  const {
    backCallback,
    callback,
    curSlide,
    emailPlaceholder,
    messagePlaceholder,
    namePlaceholder,
    response,
    slideNumber,
    submitting,
    title,
    valid,
    validate
  } = props;

  const classes = classnames('contact-slide', {
    active: curSlide === slideNumber
  });

  const submitClasses = classnames('contact-submit', { disabled: !valid });
  return (
    <div className={classes}>
      <div className="contact-page__title">{title}</div>
      <div className="contact-page__inputs">
        <input
          type="text"
          id="name"
          name="Name"
          onBlur={el => validate(el)}
          onChange={el => validate(el)}
          placeholder={namePlaceholder}
        />
        <input
          type="text"
          id="email"
          name="E-Mail"
          onBlur={el => validate(el)}
          onChange={el => validate(el)}
          placeholder={emailPlaceholder}
        />
        <textarea
          type="message"
          id="message"
          placeholder={messagePlaceholder}
        />

        {response === 'failure' && (
          <div className="contact-error">
            There was an error with the form. Please try again.
          </div>
        )}
      </div>
      <div className="contact-actions">
        <div onClick={() => backCallback(slideNumber)} className="contact-nav">
          <IoIosArrowDropleft /> Back
        </div>
        {!submitting && curSlide === slideNumber && (
          <button
            className={submitClasses}
            onClick={e => {
              callback(e);
            }}
            type="submit"
          >
            Submit <IoIosArrowDropright />
          </button>
        )}

        {submitting && curSlide === slideNumber && <Loader />}
      </div>
    </div>
  );
}

ContactSlide.propTypes = propTypes;

export default ContactSlide;
